import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { 
    ContactContainer, 
    ContactForm, 
    ContactFormFields, 
    ContactFormInfoInput, 
    ContactFormInfoTitle, 
    ContactFormWrapper, 
    ContactInfo,
    ContactInfoLink,
    ContactInfoWrapper, 
    ContactWrapper, 
    FormColumn, 
    FormRow,
    Header, 
    HeaderTopLine, 
    HeaderWrapper, 
    ButtonContainer,
    ErrorMessage,
    RequiredErrorMessage,
    ContactFormAutocomplete,
    CustomerAddressWrapper,
    CustomerInputDeleteIcon,
    CustomerInputLocateIcon,
    ContactFormAddressInput
} from './LeadElements';
import './data.js';
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';
// import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconContext } from 'react-icons'
import { ButtonElement } from '../ContactForm/ContactFormElements';
import { Oval } from 'react-loading-icons';
import 'react-dropdown/style.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { Select } from '@mui/base/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';

const Lead = ({
    topLine,
    headline,
    description,
    address,
    phone,
    email,
}) => {

    const onPlaceSelected = useRef(() => undefined);

    const gaEventTracker = useAnalyticsEventTracker('Lead Form');
    // const captchaRef = useRef(null);

    const initialFormState = {
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contactAddress: '',
        monthlyBill: '',
        message: '',
        type:'',
        salesRep:'',
        password:'lunex'
    };

    const initialErrorFormState = {
        hasEmailAndIsValid: false,
        emailErrorMessage: '',
    }

    const options = [
        'Home Show', 'USF', 'Firestone Grand Prix', 'CE Leads', 'Other'
    ];

    const [dropdownValue, setDropdownValue] = useState(options[0]);
    const [date, setDate] = useState(null);
    // const defaultOption = options[0];

    const [formState, setFormState] = useState(initialFormState);
    const [errorFormState, setErrorFormState] = useState(initialErrorFormState);
    const [submitting, setSubmitting] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    // const [captcha, setCaptcha] = useState(true);
    const [showLocate, setShowLocate] = useState(false);

    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitting(true);
        await postSubmission();
        setSubmitting(false);
    };

    const handleError = (error) => {
        let errorMessage = 'There was an error';
      
        if (error.response) {
          // Server responded with a status code that falls outside the 2xx range
          switch (error.response.status) {
            case 401:
              errorMessage = 'Incorrect Password';
              break;
            case 403:
              errorMessage = 'Access Denied';
              break;
            // Add more cases as needed
            default:
              errorMessage = 'Something went wrong. Please try again later.';
          }
        } else if (error.request) {
          // No response was received from the server
          errorMessage = 'Cannot reach the server. Please check your connection.';
        }
      
        toast.error(errorMessage, { theme: "colored", position: toast.POSITION.BOTTOM_RIGHT });
      };

    const postSubmission = async () => {

        try {
            var body = JSON.stringify({
                "name": formState.contactName,
                "address": formState.contactAddress,
                "email": formState.contactEmail,
                "phone": formState.contactPhone,
                "electricBill": formState.monthlyBill,
                "message": formState.message,
                "type":dropdownValue,
                "salesRep":formState.salesRep,
                "password":formState.password,
                "apptDate":date
            });
            
            var headers = {
                'Content-Type': 'application/json',
            }

            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'lead',
                headers: headers,
                data: body
            }
            
            const result2 = await axios(config);
            console.log(result2);
            setFormState(initialFormState);
            setErrorFormState(initialErrorFormState)
            setDropdownValue(options[0]);
            setDate(null);
            setShowLocate(false);
            setDisabledButton(true);
            toast.success('Lead form sent successfully', { theme: "colored", position: toast.POSITION.BOTTOM_RIGHT });

        } catch (error) {
            console.log(error);
            handleError(error);

        }
    };

    const updateFormControl = (event) => {
        const { id, value } = event.target;
        const formKey = id;
        const updatedFormState = { ...formState };
        const updatedErrorFormState = {...errorFormState};
        

        // Validate Fields
        switch(id) {
            case 'contactEmail':
                if(value.length === 0){
                    updatedErrorFormState.hasEmailAndIsValid = false;
                    updatedErrorFormState.emailErrorMessage = '';
                    updatedFormState[formKey] = value;
                    break;
                }
                if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    updatedErrorFormState.hasEmailAndIsValid = false;
                    updatedErrorFormState.emailErrorMessage = 'Email is invalid';
                    updatedFormState[formKey] = value;
                    break;
                }
                else{
                    updatedErrorFormState.hasEmailAndIsValid = true;
                    updatedErrorFormState.emailErrorMessage = '';
                    updatedFormState[formKey] = value;
                }
                break;
            case 'contactPhone':
                const formattedPhoneNumber = formatPhoneNumber(value);
                updatedFormState[formKey] = formattedPhoneNumber;
                break;
            default:
                updatedFormState[formKey] = value;
                break;
                
        }

        function formatPhoneNumber(value) {
            // if input value is falsy eg if the user deletes the input, then just return
            if (!value) return value;
          
            // clean the input for any non-digit values.
            const phoneNumber = value.replace(/[^\d]/g, '');
          
            // phoneNumberLength is used to know when to apply our formatting for the phone number
            const phoneNumberLength = phoneNumber.length;
          
            // we need to return the value with no formatting if its less then four digits
            // this is to avoid weird behavior that occurs if you  format the area code to early
          
            if (phoneNumberLength < 4) return phoneNumber;
          
            // if phoneNumberLength is greater than 4 and less the 7 we start to return
            // the formatted number
            if (phoneNumberLength < 7) {
              return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
          
            // finally, if the phoneNumberLength is greater then seven, we add the last
            // bit of formatting and return it.
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
              3,
              6
            )}-${phoneNumber.slice(6, 10)}`;
          }

        

        if(updatedErrorFormState.hasEmailAndIsValid)
        {   
            setDisabledButton(false)
        }
        else {
            setDisabledButton(true);
        }
        setErrorFormState(updatedErrorFormState);
        setFormState(updatedFormState);
        
    };

    const updateFormControlAddy = useCallback((event) => {
        const updatedFormState = { ...formState };
        updatedFormState['contactAddress'] = event.formatted_address;
        setFormState(updatedFormState);
      }, [formState,setFormState]);
    
      const handleLocate = async () => {
        setShowLocate(true);
        try{
          navigator.geolocation.getCurrentPosition(async function(position) {

            // 
    
            const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=' + process.env.REACT_APP_GOOGLE_MAPS_API;
    
            const result = await axios.get(url);

            
    
            updateFormControlAddy(result.data.results[0]); 
    
          });
        }
        catch(error){
            toast.error("Error encountered getting location", { theme: "colored", position: toast.POSITION.BOTTOM_RIGHT });
          // console.log("Error encounterd while getting location ", error);
        }
      }
    
      const handleDelete = () => {
        setShowLocate(false);
        const updatedFormState = { ...formState };
        updatedFormState['contactAddress'] = '';
        setFormState(updatedFormState);
      }

    //   function handleCaptcha(value) {
    //     setCaptcha(false);
    //     // console.log('Captcha value:', value);
    // }

    // function gmNoop() { console.log('GMap Callback') };

    // window && (window.gmNoop = () => {});

      useEffect(() => {
        onPlaceSelected.current = (place) => {
          updateFormControlAddy(place[0]);
        };
      }, [updateFormControlAddy]);


    

  return (
        
        <ContactContainer id='leadform'>
            <ToastContainer />
            <HeaderWrapper>
                <HeaderTopLine>{topLine}</HeaderTopLine>
                <Header>{headline}</Header>
            </HeaderWrapper>
            <ContactWrapper>
                <ContactInfoWrapper>
                    {/* <ContactInfo>{description}</ContactInfo> */}
                    <ContactInfo>{address}</ContactInfo>
                    <ContactInfoLink href='tel:+1-813-540-8807' onClick={()=>gaEventTracker('call')}>{phone}</ContactInfoLink>
                    <ContactInfo></ContactInfo>
                    <ContactInfoLink href={`mailto:${email}`} onClick={()=>gaEventTracker('email')}>{email}</ContactInfoLink>
                </ContactInfoWrapper>
                <ContactFormWrapper>
                    <ContactForm onSubmit={submitForm}>
                        <ContactFormFields>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactName'>Name</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='contactName' value={formState.contactName} onChange={updateFormControl} ></ContactFormInfoInput>                            
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='monthlyBill'>Monthly Bill</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='monthlyBill' value={formState.monthlyBill} onChange={updateFormControl} ></ContactFormInfoInput>  
                                </FormColumn>
                                
                                
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactAddress'>Address</ContactFormInfoTitle>
                                    <CustomerAddressWrapper>
                                        <IconContext.Provider
                                            value={{ size: '35px' }}
                                        >
                                            {!showLocate &&
                                                <>
                                                    <ContactFormAutocomplete 
                                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                                                        onPlaceSelected={(...args) => onPlaceSelected.current(args)}
                                                        options={{
                                                            types: ["address"],
                                                            componentRestrictions: { country: "us" },
                                                        }}
                                                        
                                                        id='contactAddress'
                                                        defaultValue={formState.contactAddress} 
                                                    />
                                                    <CustomerInputLocateIcon 
                                                        onClick={handleLocate}
                                                    />
                                                </>
                                            }
                                            {showLocate &&
                                                <>
                                                    <ContactFormAddressInput type='text' id='contactAddress' value={formState.contactAddress} onChange={(updateFormControlAddy)} />
                                                    <CustomerInputDeleteIcon 
                                                        onClick={handleDelete}
                                                    />
                                                </>
                                            }

                                        </IconContext.Provider>
                                    </CustomerAddressWrapper>
                                     
                                </FormColumn>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <FormColumn>
                                        <ContactFormInfoTitle htmlFor='apptDate'>Appointment Date</ContactFormInfoTitle>
                                        <DateTimePicker 
                                        label="Appointment Date" 
                                        id="apptDate" 
                                        value={date} 
                                        onChange={(e)=>setDate(e)} 
                                        slotProps={{ textField: { size: 'small' } }} 
                                        minutesStep={30}
                                        minTime={dayjs().set('hour', 7)}
                                        maxTime={dayjs().set('hour', 22)}
                                        />
                                    </FormColumn>
                                </LocalizationProvider>
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactPhone'>Phone number</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='contactPhone' value={formState.contactPhone} onChange={updateFormControl} ></ContactFormInfoInput>
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactEmail'>Email</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='contactEmail' value={formState.contactEmail} onChange={updateFormControl} ></ContactFormInfoInput>
                                    {errorFormState.emailErrorMessage && <ErrorMessage>{errorFormState.emailErrorMessage}</ErrorMessage>}
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='type'>Type</ContactFormInfoTitle>
                                    <FormControl size="small">
                                        <InputLabel id="type">Type</InputLabel>
                                        <Select
                                            labelId="type"
                                            id="type"
                                            value={dropdownValue}
                                            label="Type"
                                            onChange={(e)=>setDropdownValue(e.target.value)}
                                        >
                                            {options.map((option) => (
                                                <MenuItem
                                                    key={option}
                                                    value={option}
                                                >
                                                {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {/* <Select id='type' options={options} onChange={(e)=>setDropdownValue(e)} value={dropdownValue} placeholder="Select an option" />;   */}
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='salesRep'>Sales Rep</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='salesRep' value={formState.salesRep} onChange={updateFormControl} ></ContactFormInfoInput>                            
                                </FormColumn>
                                
                            </FormRow>
                            
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='message'>Message</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='message' value={formState.message} onChange={updateFormControl} ></ContactFormInfoInput>  
                                </FormColumn>
                                {/* <FormColumn>
                                    <ContactFormInfoTitle htmlFor='password'>Password</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='password' id='password' value={formState.password} onChange={updateFormControl} ></ContactFormInfoInput>  
                                </FormColumn> */}
                            </FormRow>
                            {/* <FormRow>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <FormColumn>
                                        <DatePicker label="Appointment Date" />
                                    </FormColumn>
                                </LocalizationProvider>
                            </FormRow> */}
                        </ContactFormFields>
                        {/* <CaptchaContainer>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={handleCaptcha}
                                ref={captchaRef}
                            />
                        </CaptchaContainer> */}
                        <ButtonContainer>
                        {!submitting &&
                            <ButtonElement type="submit" disabled={disabledButton || submitting} onClick={()=>gaEventTracker('submit')}>Submit</ButtonElement>

                        }
                        {submitting &&
                            <ButtonElement>
                                <Oval height='1.5rem'/>
                            </ButtonElement>
                        }
                        </ButtonContainer>
                        {disabledButton && (
                            <RequiredErrorMessage>Email Required</RequiredErrorMessage>
                        )}
                        
                    </ContactForm>
                </ContactFormWrapper>
            </ContactWrapper>

        </ContactContainer>
  )
}

export default Lead