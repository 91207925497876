import React, { useEffect, useState } from 'react';
import { IconButton, Grid, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/AddAPhoto';
import FileUpload from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { ImagePreviewContainer, ImagePreview, UploadButtonContainer, ImageUploadContainer } from './NewDealElements';
import { FileIcon, defaultStyles } from 'react-file-icon'; // Define these styled components

export function ImageUpload({ id, selectedFiles, onFilesSelected, label }) {
    const [internalFiles, setInternalFiles] = useState([]);

    const uniqueInputId = `icon-button-file-${id}`;

    useEffect(() => {
        setInternalFiles(selectedFiles);
    }, [selectedFiles]);

    const handleImageChange = (event) => {
        const newFiles = Array.from(event.target.files);
        // Update based on existing state to keep component self-contained
        const updatedFiles = [...internalFiles, ...newFiles].slice(0, 10); // Adjust the slice as needed
        setInternalFiles(updatedFiles);
        if (onFilesSelected) {
            onFilesSelected(updatedFiles);
        }
    };

    const handleRemoveImage = (index) => {
        const updatedFiles = internalFiles.filter((_, i) => i !== index);
        setInternalFiles(updatedFiles);
        if (onFilesSelected) {
            onFilesSelected(updatedFiles);
        }
    };

    const renderFilePreview = (file, index) => {
        const isImage = file.type.startsWith('image/');
        return (
          <ImagePreview key={index}>
            {isImage ? (
              <img src={URL.createObjectURL(file)} alt={`upload-${index}`} style={{ width: '100%', height: '100%' }} />
            ) : (
              <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileIcon extension={file.name.split('.').pop()} {...defaultStyles[file.name.split('.').pop()]} />
              </div>
            )}
            <IconButton size="small" onClick={() => handleRemoveImage(index)} style={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </ImagePreview>
        );
      };

  return (
    <ImageUploadContainer>
      <Typography sx={{ textAlign: 'left', marginBottom: '10px', marginLeft:'5px' }}>
        {label}
      </Typography>
      <UploadButtonContainer>
          <input accept="image/*,.pdf" multiple style={{ display: 'none' }} id={uniqueInputId} type="file" onChange={handleImageChange} />
          <label htmlFor={uniqueInputId}>
              <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
              </IconButton>
          </label>
          <IconButton color="primary" aria-label="upload file" component="span" onClick={() => document.getElementById(uniqueInputId).click()}>
              <FileUpload />
          </IconButton>
      </UploadButtonContainer>
      <ImagePreviewContainer>
        {internalFiles.map((file, index) => renderFilePreview(file, index))}
      </ImagePreviewContainer>
      
        
    </ImageUploadContainer>
  );
}
