import React from 'react';
import Layout from '../../components/Layout';
import styled from 'styled-components';

export const ImageObj = {
    page1: require('../../images/commercial/1.png'),
    page2: require('../../images/commercial/3.png'),
    page3: require('../../images/commercial/4.png'),
    page4: require('../../images/commercial/5.png'),
    page5: require('../../images/commercial/6.png'),
    page6: require('../../images/commercial/7.png'),
    page7: require('../../images/commercial/8.png'),
    page8: require('../../images/commercial/9.png'),
    page9: require('../../images/commercial/10.png'),
    page10: require('../../images/commercial/Sample Projects.png'),
    page11: require('../../images/commercial/12.png'),
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin-top: -80px;
  background-color: #38b6ff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PDFViewerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PDFPageImage = styled.img`
  width: 100%;
  max-width: 800px;
  display: block;
  margin: 0 auto;
  ${({ isFirstPage }) => isFirstPage && `
    margin-top: -150px;

    @media (max-width: 768px) {
      margin-top: 0;
    }
  `}
`;

const PDFPage = ({ src, isFirstPage }) => (
  <PDFPageImage src={src} alt="PDF Page" isFirstPage={isFirstPage} />
);

const PDFViewer = ({ pages }) => (
  <PDFViewerContainer>
    {pages.map((page, index) => (
      <PDFPage key={index} src={page} isFirstPage={index === 0} />
    ))}
  </PDFViewerContainer>
);

const Brochure = () => {
  const pages = Object.values(ImageObj);

  return (
    <Layout>
      <Wrapper>
        <PDFViewer pages={pages} />
      </Wrapper>
    </Layout>
  );
};

export default Brochure;
