import { createGlobalStyle } from "styled-components";
import AvenirBlack from "./fonts/AvenirLTStd-Black.otf";
import AvenirBook from "./fonts/AvenirLTStd-Book.otf";
// import RobotoWoff2 from "./fonts/roboto-condensed-v19-latin-regular.woff2";

const FontStyles = createGlobalStyle`

@font-face {
    font-family: 'Avenir_Black';
    src: local('Avenir_Black'), url(${AvenirBlack}) format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;

  }

  @font-face {
    font-family: 'Avenir_Book';
    src: local('Avenir_Book'), url(${AvenirBook}) format('opentype');
    font-display: swap;

  }
`;


export default FontStyles;

