import React, {useState} from 'react'
import { Button } from '../ButtonElement'
import { 
  HeroBg, 
  ImageBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,  
  ScrollArrow,
  HeroContainer
} from './HeroElements';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer>
        <HeroBg>
            <ImageBg />
        </HeroBg>
        <HeroContent>
          <HeroH1>Expert Solar Solutions</HeroH1>
          <HeroP>
          Even as one of the fastest growing solar companies in the United States, we continue to stand by our principles in delivering the most cost-effective options to our clients.
          </HeroP>
          <HeroBtnWrapper>
            <Button to='about' smooth={true} duration={500} spy={true} exact='true' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' offset={-60}>
              Explore more
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
        <ScrollArrow to='about' smooth={true} duration={500} spy={true} exact='true' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' offset={-60}/>
    </HeroContainer>
  )
}

export default HeroSection