import React from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import ethicsImage from '../../images/commercial/school.webp'; // Replace with your image path

const CustomContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#1BA8F1', // Adjust the color to match the image's background
//   padding: '50px 20px',
});

const Content = styled(Box)({
  maxWidth: '1000px',
  position: 'relative',
  margin: '50px',
  color: '#ffffff', // Adjust the color to match the image's text color
  textAlign: 'left',
});

const VerticalBars = styled('div')({
  position: 'absolute',
  top: 0,
  right: '-40px', // Adjusted to make sure it aligns with the edge
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '60%',
  padding: '20px 0',

  '&::before, &::after, div': {
    content: '""',
    width: '5px',
    backgroundColor: '#ffffff',
    margin: '20px 0',
  },

  '&::before': {
    height: '300px', // Large
  },

  div: {
    height: '160px', // Medium
  },

  '&::after': {
    height: '120px', // Small
  },
});

const SmallRectangle = styled('div')({
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  width: '50px',
  height: '50px',
  backgroundColor: '#33c0f4', // Slightly lighter color
  opacity: 0.5, // Slightly transparent
});

const Ethics = () => {
  return (
    <CustomContainer>
      <Content>
        <Typography variant="h3" component="h3" sx={{ color: '#FFA500', textAlign: 'left', marginBottom: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
          COMMITMENT TO ETHICS
        </Typography>
        <Typography variant="h5" component="p" sx={{ color: '#ffffff', textAlign: 'left', marginBottom: '20px' }}>
          We are committed to upholding the highest standards of ethics and sustainability in everything we do. Our dedication to ethical practices not only guides our business decisions but also shapes our impact on the environment and the communities we serve. We adhere to a set of core ethical principles:
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <img src={ethicsImage} alt="Ethics" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
            <ListItem>
                <ListItemText
                    primary={
                    <Typography variant="h5" component="span" sx={{ color: '#000', fontWeight: 'bold' }}>
                        {"Integrity: "}
                    </Typography>
                    }
                    secondary={
                    <Typography variant="h6" component="span" sx={{ color: '#ffffff' }}>
                        We conduct our business with honesty, transparency, and accountability.
                    </Typography>
                    }
                />
                </ListItem>
                <ListItem>
                <ListItemText
                    primary={
                    <Typography variant="h5" component="span" sx={{ color: '#000', fontWeight: 'bold' }}>
                        {"Respect: "} 
                    </Typography>
                    }
                    secondary={
                    <Typography variant="h6" component="span" sx={{ color: '#ffffff' }}>
                        We respect the environment, our employees, customers, and stakeholders.
                    </Typography>
                    }
                />
                </ListItem>
                <ListItem>
                <ListItemText
                    primary={
                    <Typography variant="h5" component="span" sx={{ color: '#000', fontWeight: 'bold' }}>
                        {"Excellence: "}
                    </Typography>
                    }
                    secondary={
                    <Typography variant="h6" component="span" sx={{ color: '#ffffff' }}>
                        We strive for excellence in our products, services, and customer relations.
                    </Typography>
                    }
                />
                </ListItem>
                <ListItem>
                <ListItemText
                    primary={
                    <Typography variant="h5" component="span" sx={{ color: '#000', fontWeight: 'bold' }}>
                        {"Innovation: "}
                    </Typography>
                    }
                    secondary={
                    <Typography variant="h6" component="span" sx={{ color: '#ffffff' }}>
                        We continuously innovate to improve sustainability and efficiency in solar energy solutions.
                    </Typography>
                    }
                />
                </ListItem>
            </List>
          </Grid>
        </Grid>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', color: '#000', textAlign: 'left', marginTop: '40px', marginBottom: '20px' }}>
          Why Clean Ethics Matter
        </Typography>
        <Typography variant="body1" component="p" sx={{ color: '#ffffff', textAlign: 'left', lineHeight: 2.0, marginBottom: '20px' }}>
          In today's business landscape, ethical practices and sustainability are more relevant than ever. They not only enhance a company's reputation but also attract customers who prioritize environmental responsibility. Businesses that transition to clean power can actively participate in and contribute to the global conversation on sustainability.
        </Typography>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', color: '#000', textAlign: 'left', marginTop: '40px', marginBottom: '20px' }}>
          Joining the Conversation
        </Typography>
        <Typography variant="body1" component="p" sx={{ color: '#ffffff', textAlign: 'left', lineHeight: 2.0 }}>
          By partnering with Lunex Power, businesses can further integrate clean ethics into their operations, benefitting their public image. Together, we can create a more sustainable future while enhancing your company's reputation as a responsible corporate entity.
        </Typography>
        <VerticalBars>
          <div></div>
        </VerticalBars>
        <SmallRectangle />
      </Content>
    </CustomContainer>
  );
};

export default Ethics;
