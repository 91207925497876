import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Paper, Link, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Logo, StyledContainer, ErrorStyled } from '../NewDeal/NewDealElements';
import styled from 'styled-components';

export const Title = styled.h2`
  /* font-size: 36px; // Mobile-friendly font size */
  color: #333; // Dark color for text
  margin-bottom: 15px; // Space below the title
  font-family: 'Avenir_Black';
`;

const OnboardingSign = ({img, alt}) => {

    const [onboardingDocs, setOnboardingDocs] = useState([]);
    const [salesRepName, setSalesRepName] = useState('');
    const [hasAgreed, setHasAgreed] = useState(false);
    const [userSigned, setUserSigned] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState('');

    // Use useParams to extract the user ID from the URL path
    const { id } = useParams();

    useEffect(() => {
        const fetchOnboardingDocs = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/getOnboardingDocs/${id}`);
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message || 'Network response was not ok');
                }
                const data = await response.json();
                console.log("data", data);
                setSalesRepName(data.salesRepName);
                const docs = data.onboardingDocs.map(doc => {
                    const file = new Blob([new Uint8Array(doc.fileData.data)], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    return { ...doc, fileURL };
                });
                setOnboardingDocs(docs);
                setLoading(false);
                setError(false);
            } catch (error) {
                setLoading(false);
                setError(true);
                console.error('Failed to fetch onboarding documents:', error);
                setErrorMessage(error.message || "An error occurred while fetching data");
            }
        };
    
        fetchOnboardingDocs();
    }, [id]);
    

    const handleAgree = () => {
        setHasAgreed(true);
    };

    const createUserAccounts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/createUserAccounts/${id}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('User accounts created successfully');
        } catch (error) {
            console.error('Failed to create user accounts:', error);
        }
    };

    const handleSignNow = async () => {
        setSubmitting(true);
        setSubmitErrorMessage('');
        // Send confirmation to the backend
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/confirmOnboarding/${id}`, {
                method: 'GET',
            });
            if(response.status === 0){
                console.log("Network error occurred");
                setSubmitErrorMessage("Network error occurred. Please check your internet connection.");
                throw new Error('Network error occurred');
            }
            if (!response.ok) {
                console.log("Response not ok");
                setSubmitErrorMessage("Submission failed");
                throw new Error('Network response was not ok');
            }
            setUserSigned(true);
            setSubmitting(false);
            await createUserAccounts();
        } catch (error) {
            console.error('Failed to send confirmation:', error);
            setSubmitErrorMessage("Submission failed");
        }

        setSubmitting(false);
    };

    // const openPdfInNewTab = (pdfData) => {
    //     const blob = new Blob([new Uint8Array(pdfData)], { type: 'application/pdf' });
    //     const url = URL.createObjectURL(blob);
    //     window.open(url, '_blank');
    // };


    return (
        <StyledContainer>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
                <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                {loading &&
                    <Title>
                        Loading...
                    </Title>
                }
                {!loading && 
                    <>
                        <Logo src={img} alt={alt} style={{ width: '200px', height: 'auto' }} />
                        <Title>
                            Onboarding Form
                        </Title>
                        {error &&
                            <ErrorStyled>
                                {errorMessage}
                            </ErrorStyled>
                        }
                        {!error &&
                            <>
                                <Typography variant="subtitle1" textAlign="center">
                                    Welcome, {salesRepName}. Please review and agree to the onboarding documents below.
                                </Typography>
                                <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                                    {onboardingDocs.map((doc, index) => (
                                        <Link key={index} href={doc.fileURL} target="_blank" rel="noopener noreferrer" variant="body1">
                                            {doc.key}
                                        </Link>
                                    ))}
                                </Box>
                                {!userSigned ? (
                                    <>
                                        
                                        {!hasAgreed ? (
                                            <>
                                                <Typography variant="body1" textAlign="center" mt={2}>
                                                    Have you read and do you agree to the terms?
                                                </Typography>
                                                <Button variant="outlined" color="primary" onClick={handleAgree}>
                                                    I Agree
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Typography variant="body1" textAlign="center" mt={2}>
                                                    Do you confirm that you are {salesRepName} and wish to sign?
                                                </Typography>
                                                <Button variant="contained" style={{minWidth: '155px'}} color="secondary" onClick={handleSignNow} disabled={submitting}>
                                                    {submitting ? <CircularProgress size={24} /> : 'Confirm & Sign'}
                                                </Button>
                                                {submitErrorMessage &&
                                                    <ErrorStyled>
                                                        {submitErrorMessage}
                                                    </ErrorStyled>
                                                }
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Typography variant="body1" textAlign="center" mt={2}>
                                        Thank you for completing the onboarding documents. You will receive a copy of the signed documents once both parties have signed.
                                    </Typography>
                                )}
                            </>
                        }
                    </>
                }
                </Paper>
            </Box>
        </StyledContainer>
    );
};

export default OnboardingSign;
