import React from 'react'
import ReferForm from '../../components/ReferForm'
import Layout from '../../components/Layout'
import { referObj } from '../../components/ReferForm/data'

const Refer = ({source}) => {
  return (
    <div style={{backgroundColor:'#fff'}}>
      <Layout>
        <ReferForm 
            {...referObj}
            source={source}
        />
      </Layout>
    </div>
  )
}

export default Refer