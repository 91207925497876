export const CollabObj = {
    header: 'We collaborate with leading manufacturers',
    qcells: require('../../images/collab/qcells.webp'),
    qcellsAlt: "QCells Solar Cells",
    rec: require('../../images/collab/rec.webp'),
    recAlt: "REC Solar Panels",
    enphase: require('../../images/collab/enphase.webp'),
    enphaseAlt: "Enphase Energy",
    tesla: require('../../images/collab/tesla.webp'),
    teslaAlt: "Tesla Energy Certified Installer",
    batteryHeader: "Ask us about home energy storage and backup solutions",
    teslaBattery: require('../../images/collab/teslaBattery2.webp'),
    teslaBatteryAlt: "Tesla Home Energy Storage Solutions",
    usf: require('../../images/collab/usfbanner.webp'),
    usfAlt: "Official Solar Company of USF Athletics",
    franklin: require('../../images/collab/franklin.webp'),
    franklinAlt: "FranklinWH Energy Storage",
};