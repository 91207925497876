export const homeObjOne = {
    id: 'about',
    lightbg: 1,
    lighttext: 0,
    lightTextDesc: false,
    topLine: 'ABOUT LUNEX POWER',
    headline: 'The most sustainable source of energy',
    description: 'Lunex Power has over 50 years of collective experience in the solar industry. With our primary focus being in design, engineering and construction; our projects are accurately quoted and exquisitely built. From utility scale projects to neighborhood residences, we\'ve got you covered.',
    // buttonLabel: 'Get Started',
    imgstart: 1,
    img: require('../../images/Images/Homepage/AboutUs1.webp'),
    alt: 'image description',
    dark: true,
    primary: true,
    darktext: 1,
    hasImage: true,
    hasButton: false
};

export const homeObjTwo = {
    lightbg: 0,
    lighttext: 1,
    lightTextDesc: true,
    topLine: 'OUR STORY',
    headline: 'Poised to dominate the market',
    description: `Lunex Power is a locally-owned solar engineering and procurement contractor. Our team is committed to delivering the most advanced solar & electrical solutions paired with excellent customer service for each and every one of our clients. Originally serving New England for over 7 years, Lunex Power’s employees are collectively responsible for the installation and procurement of over 2,500 average residential solar projects. Our goal is to be the most responsive and knowledgeable company in the country and beyond!`,
    imgstart: 0,
    dark: true,
    primary: true,
    darktext: 0,
    hasVideo: true,
    hasButton: false
};

export const homeObjThree = {
    lightbg: 1,
    lighttext: 0,
    lightTextDesc: false,
    topLine: '',
    headline: 'An informative and easy process',
    description: `Our goal is to provide you an educated and transparent experience in making the transition to solar energy, because at the end of the day, everyone deserves to make the switch.`,
    imgstart: 0,
    dark: false,
    primary: false,
    darktext: 1,
    hasButton: false,
    hasCounter: true
};


