import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImageUpload } from './ImageUpload';
import { Typography, AccordionSummary, AccordionDetails } from '@mui/material';
import { StyledContainer, Logo, Title, CustomerData, StyledAccordion, TextField, ErrorStyled, SuccessStyled } from './NewDealElements';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import { formFieldsConfig } from './data.js';
import { ClearableSelect } from '../../helpers/helperFunctions.js';

function DealForm({img, alt}) {
    const { id } = useParams();
    const [customer, setCustomer] = useState({
        name: '',
        address: '',
        phone: '',
        email: '',
      });

      const [utilityBill, setUtilityBill] = useState([]);

      const [adders, setAdders] = useState({
        moduleAdders: '',
        battery: '',
        batteryQuantity: '',
        roofAdders: '',
        mountingAdders: '',
        sunnovaBatteryWarranty: '',
        batteryInsideGarage: '',
        electricalAdders: '',
        travelAdder: '',
        structuralReinforcement: '',
        evCharger: '',
        evChargerQuantity: '',
        acSoftStart: '',
        conciergeFee: '',
        groundMount: '',
        dirtTrenching: '',
        hardscapeTrenching: '',
        trenchOver100ft: '',
        groundMountBobcatRental: '',
        solarInsure: '',
        tier2Insurance: '',
        declarationPage: []
      });
      
      const [siteSurvey, setSiteSurvey] = useState({
        droneOr360WalkAround: [],
        frontOfHome: [],
        rightSideOfHome: [],
        leftSideOfHome: [],
        backOfHome: [],
        treesToBeRemoved: [],
        utilityMeter: [],
        atticRafters: [],
        rafterSize: '',
        rafterSpacing: '',
        roof: [],
        mainServicePanelDoorClosed: [],
        mainServicePanelDoorOpen: [],
        mainServicePanelBreakers: [],
        mainServicePanelDiagram: []
      });
      const [isLoading, setIsLoading] = useState(true);
      const [submitting, setSubmitting] = useState(false);
      const [validId, setValidId] = useState(false);
      const [success, setSuccess] = useState(false);
      const [errorMessage, setErrorMessage] = useState("");
      const [submitErrorMessage, setSubmitErrorMessage] = useState("");
    
      useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}service/getDeal/${id}`;

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if(data.message === 'No deal found or security id has expired'){
              setErrorMessage("No Deal Found");
            }
            setCustomer({
              name: data.deal.name,
              address: data.deal.address,
              phone: data.deal.phone,
              email: data.deal.email,
            });
            
            setIsLoading(false);
            setValidId(true);

          })
          .catch((error) => {
            console.error('Error fetching customer data:', error);
            setIsLoading(false);
            setValidId(false);
          });
      }, [id]);
    
    const handleChange = (event) => {
      const { name, value } = event.target;
      if (name in siteSurvey) {
        setSiteSurvey(prev => ({ ...prev, [name]: value }));
      } else {
        setAdders(prev => ({ ...prev, [name]: value }));
      }
    };

    async function requestSignedUrls(fileDetails) {
      const url = `${process.env.REACT_APP_API_URL}service/generateSignedUrls/${id}`;
      const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(fileDetails),
          headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
          throw new Error('Failed to get signed URLs');
      }
      return await response.json();
    }

    function prepareFileDetails() {
      const filesMapping = {};
    
      // Utility Bill
      if (utilityBill.length > 0) {
        filesMapping['utilityBill'] = utilityBill.map((file, index) => ({
          fileName: `utilityBill_${Date.now()}_${index}.${getFileExtension(file.name)}`,
          fileKey: 'utilityBill',
          fileType: file.type,
          fileData: file
        }));      
      }
    
      // Declaration Page
      if (adders.declarationPage && adders.declarationPage.length > 0) {
        filesMapping['declarationPage'] = adders.declarationPage.map((file, index) => ({
          fileName: `declarationPage_${Date.now()}_${index}.${getFileExtension(file.name)}`,
          fileKey: 'declarationPage',
          fileType: file.type,
          fileData: file
        }));
      }
    
      // Site Survey Sections
      Object.entries(siteSurvey).forEach(([key, value]) => {
        // console.log("ss keys", key);
        // console.log("ss value", value);
        if (Array.isArray(value) && value.length > 0) {
          filesMapping[key] = value.map((file, index) => ({
            fileName: `${key}_${Date.now()}_${index}.${getFileExtension(file.name)}`,
            fileKey: key,
            fileType: file.type,
            fileData: file
          }))
        }
      });
    
      return filesMapping;
    }
    
    function getFileExtension(fileName) {
      return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2); // Extracts file extension from fileName
    }

    async function uploadFileToGCS(file, signedUrl) {
      // console.log("uploading file******* ", file);
      // console.log("uploading file TYPE******* ", file.type);
      // console.log("upload file to gcs signed url", signedUrl);
      try {
          const response = await fetch(signedUrl, {
              method: 'PUT',
              body: file, // File to upload
              headers: {
                  'Content-Type': file.type,
              },
          });
  
          if (response.ok) {
              // Successfully uploaded, return the URL without query parameters
              return signedUrl.split('?')[0];
          } else {
            console.error('Upload failed:', await response.text());
            throw new Error(`Failed to upload file: ${response.statusText}`);
          }
      } catch (error) {
          console.error('Error uploading file to GCS:', error);
          throw error;
      }
  }
  
  async function uploadFiles(filesMapping, signedUrls) {
    // console.log("uploading files to gcs", filesMapping);
      const uploadResults = {};
  
      for (const [section, files] of Object.entries(filesMapping)) {
        // console.log("files", files);
        // console.log("file types", files.type);
        // console.log("file length", files.length);
        // console.log("section name", section);
        // for(const file of files){
        //   console.log("file----", file);
        //   console.log("file length", files.length);
        //   console.log("fileData", file.fileData);
        //   console.log("file name", file.fileName);
        //   console.log("file length", files.length);
        //   console.log("file fileType", file.fileType);
        
          const sectionUrls = signedUrls[section] || [];

          // console.log("sectionUrls", sectionUrls);
  
          // Ensure we have the same number of signed URLs as we have files
          if (files.length !== sectionUrls.length) {
              console.error(`Mismatch in files and signed URLs count for section: ${section}`);
              continue; // Skip this section due to mismatch
          }
  
          // Upload files concurrently
          const uploadPromises = files.map((file, index) =>
              uploadFileToGCS(file.fileData, sectionUrls[index])
          );
  
          try {
              // Wait for all files in this section to be uploaded
              const sectionUploadedUrls = await Promise.all(uploadPromises);
              uploadResults[section] = sectionUploadedUrls;
          } catch (error) {
              console.error(`Error uploading files for section ${section}:`, error);
          }
        // }
      }
  
      return uploadResults;
  }
  
    
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setSubmitting(true);
  
      try {

        const fileDetails = prepareFileDetails();
        // console.log("file details", fileDetails);
        const signedUrlsResponse = await requestSignedUrls(fileDetails);
        // console.log("signed url resp", signedUrlsResponse.signedUrls.utilityBill);

        // Perform the file uploads to GCS using the signed URLs
        const uploadResults = await uploadFiles(fileDetails, signedUrlsResponse.signedUrls);

        // console.log("upload results", uploadResults);

        const submitFormData = {
          adders: JSON.stringify({
              ...adders,
              declarationPage: uploadResults.declarationPage,
          }),
          utilityBill: uploadResults.utilityBill,
        };
        
        // Initialize an empty object for siteSurvey
        const siteSurveyUploadResults = {};
        
        // Iterate over uploadResults and exclude utilityBill and declarationPage
        for (const [key, value] of Object.entries(uploadResults)) {
            if (key !== 'utilityBill' && key !== 'declarationPage') {
                siteSurveyUploadResults[key] = value;
            }
        }
        
        // Assign the siteSurvey object directly if there are any keys other than utilityBill and declarationPage
        if (Object.keys(siteSurveyUploadResults).length > 0) {
            submitFormData.siteSurvey = JSON.stringify({
                ...siteSurvey,
                ...siteSurveyUploadResults
            });
        }
        else{
          submitFormData.siteSurvey = JSON.stringify({
            ...siteSurvey
          });
        }

        // console.log("form data", submitFormData);

        const url = `${process.env.REACT_APP_API_URL}service/submitDealForm/${id}`;
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(submitFormData),
          headers: { 'Content-Type': 'application/json' },
        });
        
        setSubmitting(false);

        // console.log("resp", response.status);
        if(response.status === 200){
          console.log('Submitted successfully');
          setSuccess(true);
          setUtilityBill([]);
          setAdders({
            moduleAdders: '',
            battery: '',
            batteryQuantity: '',
            roofAdders: '',
            mountingAdders: '',
            sunnovaBatteryWarranty: '',
            batteryInsideGarage: '',
            electricalAdders: '',
            travelAdder: '',
            structuralReinforcement: '',
            evCharger: '',
            evChargerQuantity: '',
            acSoftStart: '',
            conciergeFee: '',
            groundMount: '',
            dirtTrenching: '',
            hardscapeTrenching: '',
            trenchOver100ft: '',
            groundMountBobcatRental: '',
            solarInsure: '',
            tier2Insurance: '',
            declarationPage: []
          });
          setSiteSurvey({
            droneOr360WalkAround: [],
            frontOfHome: [],
            rightSideOfHome: [],
            leftSideOfHome: [],
            backOfHome: [],
            treesToBeRemoved: [],
            utilityMeter: [],
            atticRafters: [],
            rafterSize: '',
            rafterSpacing: '',
            roof: [],
            mainServicePanelDoorClosed: [],
            mainServicePanelDoorOpen: [],
            mainServicePanelBreakers: [],
            mainServicePanelDiagram: []
          });
        }
        
      } catch (error) {
        setSubmitting(false);
        setSubmitErrorMessage("Error Occurred");
        console.error('Submission error:', error);
      }
    };

    const renderDropdown = (field, key) => {
      const { name, label, options } = field;

      const fieldValue = name in adders ? adders[name] : name in siteSurvey ? siteSurvey[name] : '';


      return (
        <ClearableSelect
          key={key}
          name={name}
          label={label}
          value={fieldValue}
          onChange={handleChange}
          options={options.map(option => ({ key:option, value: option, label: option }))}
        />
      )
    };
  
    const renderTextField = (field, key) => {
      const { name, label, fieldType } = field;
      // console.log("name, label, fieldType", name, label, fieldType);
      const fieldValue = name in adders ? adders[name] : name in siteSurvey ? siteSurvey[name] : '';

      return(
        <TextField
          key={key}
          fullWidth
          margin="normal"
          name={name}
          label={label}
          type={fieldType}
          value={fieldValue}
          variant="outlined"
          onChange={handleChange}
        />
      )
    };

    const renderImageUpload = (field, key) => {
      const { name, label } = field;
      let selectedFiles, onFilesSelected;
    
      if (name === "utilityBill") {
        selectedFiles = utilityBill;
        onFilesSelected = selectedFiles => setUtilityBill(selectedFiles);
        // console.log("files selected (utility bill)", utilityBill);
      } 
      else if (name === "declarationPage") {
        selectedFiles = adders[name];
        onFilesSelected = selectedFiles => setAdders(prev => ({ ...prev, declarationPage: [...selectedFiles] }));
        // console.log("files selected (dec page)", selectedFiles);
      }
      else {
        selectedFiles = siteSurvey[name] || [];
        onFilesSelected = selectedFiles => setSiteSurvey(prev => ({ ...prev, [name]: selectedFiles }));
      }
    
      return (
          <ImageUpload
            key={key}
            id={name}
            label={label}
            selectedFiles={selectedFiles}
            onFilesSelected={onFilesSelected}
          />
      );
    };
  
  return (
    <StyledContainer onSubmit={handleSubmit}>
        <Logo src={img} alt={alt} />
        <Title>New Deal</Title>
        {isLoading &&
          <>
            <CustomerData>Loading Deal...</CustomerData>
          </>
        }
        {errorMessage &&
          <ErrorStyled>{errorMessage}</ErrorStyled>
        }
        {!errorMessage &&
          <>
            <CustomerData>{customer.name}</CustomerData>
            <CustomerData>{customer.address}</CustomerData>
            <CustomerData>{customer.phone} {customer.email}</CustomerData>
            
          </>
        }
        <br />
        {
          Object.entries(formFieldsConfig).map(([sectionName, fields], index) => (
            <StyledAccordion key={`${sectionName}-${index}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{sectionName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {fields.map((field) => {
                  // Ensure key is unique by combining section name and field name
                  const uniqueKey = `${sectionName}-${field.name}`;
                  switch (field.type) {
                    case 'dropdown':
                      return renderDropdown(field, uniqueKey);
                    case 'textField':
                      return renderTextField(field, uniqueKey);
                    case 'imageUpload':
                      return renderImageUpload(field, uniqueKey);
                    default:
                      return null;
                  }
                })}
              </AccordionDetails>
            </StyledAccordion>
          ))
        }
        <br />
        {success &&
          <>
            <SuccessStyled>Success</SuccessStyled>
            <br />
          </>
        }
        {submitErrorMessage &&
          <>
            <ErrorStyled>{submitErrorMessage}</ErrorStyled>
            <br />
          </>
        }
        {!submitting &&
          <Button type="submit" variant="contained" disabled={!validId}>Submit</Button>
        }
        {submitting &&
          <Button type="submit" variant="contained" disabled={!validId}>
            <Oval height='1.5rem'/>
          </Button>
        }
        
        
    </StyledContainer>
  );
}

export default DealForm;
