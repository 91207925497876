import React, { useEffect, useState } from 'react';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaYelp } from '@react-icons/all-files/fa/FaYelp';
import { animateScroll as scroll } from 'react-scroll'
import { 
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLink,
    FooterLinkTitle,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
    FooterLogoImg,
    FooterLinkPhone,
    FooterLinkHiring
 } from './FooterElements';
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';
import { Link, useLocation } from 'react-router-dom';

const Footer = ({ img, alt }) => {

    const [conf, setConf] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if(location.pathname !== '/'){
            setConf(true);
        }
    },[location.pathname])

    

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const gaEventTracker = useAnalyticsEventTracker('Contact Us');
  return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Company</FooterLinkTitle>
                            {conf ? (
                                <FooterLinkHiring to='/'>Home</FooterLinkHiring>
                            ) : (
                                <>
                                    <FooterLink to='about' offset={-60}>About Us</FooterLink>
                                    <FooterLink to='services' offset={-60}>Services</FooterLink>
                                    <FooterLink to='reviews' offset={-60}>Reviews</FooterLink>
                                    
                                </>
                            )
                            } 
                            <FooterLinkPhone target="_blank" href='https://blog.lunexpower.com'>Blog</FooterLinkPhone>
                            
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contact Us</FooterLinkTitle>
                            <FooterLinkPhone href='tel:+1-813-540-8807' onClick={()=>gaEventTracker('footerCall')}>(813) 540-8807</FooterLinkPhone>
                            <FooterLinkPhone href='mailto:info@lunexpower.com' onClick={()=>gaEventTracker('footerEmail')}>info@lunexpower.com</FooterLinkPhone>
                            {/* <FooterLinkHiring to='/hiring'>Join our Team</FooterLinkHiring> */}
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <FooterLogoImg to='/' onClick={toggleHome} src={img} alt={alt}/>
                        <WebsiteRights>
                            Lunex Power © {new Date().getFullYear()} All Rights Reserved.
                        </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href='https://www.facebook.com/Lunexpowerinc/' target='_blank' aria-label='Facebook'>
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href='https://www.instagram.com/lunexpowerinc/' target='_blank' aria-label='Instagram'>
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href='https://www.linkedin.com/company/lunex-power-inc/' target='_blank' aria-label='LinkedIn'>
                                <FaLinkedin />
                            </SocialIconLink>
                            {/* <SocialIconLink href='https://twitter.com/lunexpowerinc' target='_blank' aria-label='Twitter'>
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href='https://www.yelp.com/biz/lunex-power-tampa-3' target='_blank' aria-label='Yelp'>
                                <FaYelp />
                            </SocialIconLink> */}
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
  )
}

export default Footer