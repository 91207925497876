import React, {useEffect, useState} from 'react'
import  { FaBars } from '@react-icons/all-files/fa/FaBars'
import { animateScroll as scroll } from 'react-scroll'
import { 
    Nav, 
    NavbarContainer, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink,
    NavLogoImg, 
    NavButton,
    MobileIconLinks,
    NavExternalLinks,
    NavButtonLink,
    NavAlt,
    NavLinksRouter
} from './NavbarElements';
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';
import { Link, useLocation } from 'react-router-dom';


const Navbar = ({ toggle, img, alt, img2 }) => {
    const gaEventTracker = useAnalyticsEventTracker('Navbar');
    const [scrollnav, setScrollNav] = useState(0);

    const changeNav = () => {
        if(window.scrollY >= 180) {
            setScrollNav(1)
        }
        else {
            setScrollNav(0)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const [conf, setConf] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if(location.pathname !== '/'){
            setConf(true);
        }
    },[location.pathname])

  return (
    <>
        {conf ? (
            <NavAlt>
                <NavbarContainer>
                    <Link to='/'>
                        <NavLogoImg to='/' onClick={toggleHome} src={img} alt={alt} $conf={conf}/>
                    </Link>
                    <MobileIconLinks onClick={toggle}>
                        <FaBars />
                    </MobileIconLinks>
                    <NavMenu>
                        {/* <NavItem>
                            <NavLinks to='about' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('About')}>About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='services' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Services')}>Services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='reviews' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Reviews')}>Reviews</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='requestquote' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Contact Us')}>Contact</NavLinks>
                        </NavItem> */}
                    </NavMenu>                        
                    <NavBtn>
                        <NavButton as={Link} to='/'>Home</NavButton>
                    </NavBtn>                        
                </NavbarContainer>
            </NavAlt>
                
        ) :(
                <Nav $scrollnav={scrollnav}>
                    <NavbarContainer>                
                        <NavLogoImg to='/' onClick={toggleHome} src={img} alt={alt}/>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to='about' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('About')}>About</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='services' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Services')}>Services</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='reviews' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Reviews')}>Reviews</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinksRouter to='/commercial' onClick={()=>gaEventTracker('Commercial')}>Commercial</NavLinksRouter>
                            </NavItem>
                            {/* <NavItem>
                                <NavExternalLinks href='https://get.lunexpower.com/ebook/' target="_blank" onClick={()=>gaEventTracker('E-Book')}>E-Book</NavExternalLinks>
                            </NavItem> */}
                        </NavMenu>
                        <NavBtn>
                            <NavButtonLink href='https://lunexpower.com/refer-email' onClick={()=>gaEventTracker('Request a Quote')}>Refer a Friend</NavButtonLink>
                        </NavBtn>
                        <NavBtn>
                            <NavBtnLink to='requestquote' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Request a Quote')}>Request a Quote</NavBtnLink>
                        </NavBtn>
                    </NavbarContainer>
                </Nav>
            )
        }
    </>
  )
}

export default Navbar