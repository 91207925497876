import React, { useState, useRef } from 'react';
import axios from 'axios';
import { 
    ContactContainer, 
    ContactForm, 
    ContactFormFields, 
    ContactFormInfoInput, 
    ContactFormInfoTitle, 
    ContactFormWrapper, 
    ContactInfo,
    ContactInfoLink,
    ContactInfoWrapper, 
    ContactWrapper, 
    FormColumn, 
    FormRow,
    Header, 
    HeaderTopLine, 
    HeaderWrapper, 
    ButtonContainer,
    ErrorMessage,
    RequiredErrorMessage,
} from './LeadElements';
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonElement } from '../ContactForm/ContactFormElements';
import { Oval } from 'react-loading-icons';
import { CaptchaContainer } from '../LeadForm/LeadElements';
import { formatPhoneNumber } from '../../helpers/helperFunctions';

const Refer = ({
    topLine,
    headline,
    description,
    address,
    phone,
    email,
    source
}) => {
    const gaEventTracker = useAnalyticsEventTracker('Refer Form');
    const captchaRef = useRef(null);

    const initialFormState = {
        customerName: '',
        customerEmail: '',
        customerAddress: '',
        customerPhone: '',
        referralName: '',
        referralEmail: '',
        referralPhone: '',
        message: '',
    };

    const initialErrorFormState = {
        hasEmailAndIsValidReferral: false,
        emailErrorMessageReferral: '',
        hasEmailAndIsValidCustomer: false,
        emailErrorMessageCustomer: '',
        hasPhoneAndIsValid: false,
        phoneErrorMessage: ''
    }

    const [formState, setFormState] = useState(initialFormState);
    const [errorFormState, setErrorFormState] = useState(initialErrorFormState);
    const [submitting, setSubmitting] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [captcha, setCaptcha] = useState(true);

    const submitForm = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        await postSubmission();
        setSubmitting(false);
    };

    const handleError = (error) => {
        let errorMessage = 'There was an error';
      
        if (error.response) {
          // Server responded with a status code that falls outside the 2xx range
          switch (error.response.status) {
            case 403:
              errorMessage = 'Access Denied';
              break;
            // Add more cases as needed
            default:
              errorMessage = 'Something went wrong. Please try again later.';
          }
        } else if (error.request) {
          // No response was received from the server
          errorMessage = 'Cannot reach the server. Please check your connection.';
        }
      
        toast.error(errorMessage, { theme: "colored", position: toast.POSITION.BOTTOM_RIGHT });
      };

    const postSubmission = async () => {

        try {
            const token = captchaRef.current.getValue();
            const url = process.env.REACT_APP_API_URL + 'captcha/' + token
            const result = await axios.get(url)
            
            if(result.data.verified){
                var body = JSON.stringify({
                    "customerName": formState.customerName,
                    "customerEmail": formState.customerEmail,
                    "customerAddress": formState.customerAddress,
                    "customerPhone": formState.customerPhone,
                    "referralName": formState.referralName,
                    "referralPhone": formState.referralPhone,
                    "referralEmail": formState.referralEmail,
                    "message": formState.message,
                    "source":source,
                });
                
                var headers = {
                    'Content-Type': 'application/json',
                }

                const config = {
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + 'refer',
                    headers: headers,
                    data: body
                }
                
                const result2 = await axios(config);
                console.log(result2);
                setFormState(initialFormState);
                setErrorFormState(initialErrorFormState)
                setDisabledButton(true);
                toast.success('Referral sent successfully', { theme: "colored", position: toast.POSITION.BOTTOM_RIGHT });

            }
            captchaRef.current.reset();
            setCaptcha(true);
        } catch (error) {
            console.log(error);
            handleError(error);

        }
    };

    const updateFormControl = (event) => {
        const { id, value } = event.target;
        const formKey = id;
        const updatedFormState = { ...formState };
        const updatedErrorFormState = { ...errorFormState };
    
        // Validate Fields
        switch(id) {
            case 'customerEmail':
                if(value.length === 0){
                    updatedErrorFormState.hasEmailAndIsValidCustomer = false;
                    updatedErrorFormState.emailErrorMessageCustomer = 'Email is required';
                    updatedFormState[formKey] = value;
                }
                else if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    updatedErrorFormState.hasEmailAndIsValidCustomer = false;
                    updatedErrorFormState.emailErrorMessageCustomer = 'Email is invalid';
                    updatedFormState[formKey] = value;
                }
                else{
                    updatedErrorFormState.hasEmailAndIsValidCustomer = true;
                    updatedErrorFormState.emailErrorMessageCustomer = '';
                    updatedFormState[formKey] = value;
                }
                break;
            case 'referralEmail':
                if(value.length === 0){
                    updatedErrorFormState.hasEmailAndIsValidReferral = false;
                    updatedErrorFormState.emailErrorMessageReferral = '';
                    updatedFormState[formKey] = value;
                }
                else if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    updatedErrorFormState.hasEmailAndIsValidReferral = false;
                    updatedErrorFormState.emailErrorMessageReferral = 'Email is invalid';
                    updatedFormState[formKey] = value;
                }
                else{
                    updatedErrorFormState.hasEmailAndIsValidReferral = true;
                    updatedErrorFormState.emailErrorMessageReferral = '';
                    updatedFormState[formKey] = value;
                }
                break;
            case 'customerPhone':
                    const formattedCustomerPhoneNumber = formatPhoneNumber(value);
                    updatedFormState[formKey] = formattedCustomerPhoneNumber;
                    // if(value.length === 0){
                    //     updatedErrorFormState.hasPhoneAndIsValid = false;
                    //     updatedErrorFormState.phoneErrorMessage = 'Phone number is required';
                    // }
                    // else if(formattedPhoneNumber.length !== 14){
                    //     updatedFormState.phoneValid = false;
                    // }
                    // else {
                    //     updatedErrorFormState.hasPhoneAndIsValid = true;
                    //     updatedErrorFormState.phoneErrorMessage = '';
                    // }
                    break;
            case 'referralPhone':
                const formattedPhoneNumber = formatPhoneNumber(value);
                updatedFormState[formKey] = formattedPhoneNumber;
                if(value.length === 0){
                    updatedErrorFormState.hasPhoneAndIsValid = false;
                    updatedErrorFormState.phoneErrorMessage = 'Phone number is required';
                }
                else if(formattedPhoneNumber.length !== 14){
                    updatedFormState.phoneValid = false;
                }
                else {
                    updatedErrorFormState.hasPhoneAndIsValid = true;
                    updatedErrorFormState.phoneErrorMessage = '';
                }
                break;
            default:
                updatedFormState[formKey] = value;
                break;   
        }
    
        if(
            updatedErrorFormState.hasEmailAndIsValidCustomer && 
            (updatedErrorFormState.hasPhoneAndIsValid || 
            updatedErrorFormState.hasEmailAndIsValidReferral)
        ){
            setDisabledButton(false);
        }
        else {
            setDisabledButton(true);
        }
        setErrorFormState(updatedErrorFormState);
        setFormState(updatedFormState);
    };
    

    

      function handleCaptcha(value) {
        setCaptcha(false);
    }    

  return (
        
        <ContactContainer id='referform'>
            <ToastContainer />
            <HeaderWrapper>
                <HeaderTopLine>{topLine}</HeaderTopLine>
                <Header>{headline}</Header>
            </HeaderWrapper>
            <ContactWrapper>
                <ContactInfoWrapper>
                    <ContactInfo>{description}</ContactInfo>
                    <ContactInfo>{address}</ContactInfo>
                    <ContactInfoLink href='tel:+1-813-540-8807' onClick={()=>gaEventTracker('call')}>{phone}</ContactInfoLink>
                    <br />
                    <br />
                    <ContactInfoLink href={`mailto:${email}`} onClick={()=>gaEventTracker('email')}>{email}</ContactInfoLink>
                </ContactInfoWrapper>
                <ContactFormWrapper>
                    <ContactForm onSubmit={submitForm}>
                        <ContactFormFields>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='customerName'>Your Name</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='customerName' value={formState.customerName} onChange={updateFormControl} ></ContactFormInfoInput>                            
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='customerEmail'>Your Email</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='customerEmail' value={formState.customerEmail} onChange={updateFormControl} ></ContactFormInfoInput>  
                                    {errorFormState.emailErrorMessageCustomer && <ErrorMessage>{errorFormState.emailErrorMessageCustomer}</ErrorMessage>}
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='customerAddress'>Your Address</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='customerAddress' value={formState.customerAddress} onChange={updateFormControl} ></ContactFormInfoInput>                            
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='customerPhone'>Your Phone Number</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='customerPhone' value={formState.customerPhone} onChange={updateFormControl} ></ContactFormInfoInput>  
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='referralName'>Referral Name</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='referralName' value={formState.referralName} onChange={updateFormControl} ></ContactFormInfoInput>
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='referralEmail'>Referral Email</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='referralEmail' value={formState.referralEmail} onChange={updateFormControl} ></ContactFormInfoInput>
                                    {errorFormState.emailErrorMessageReferral && <ErrorMessage>{errorFormState.emailErrorMessageReferral}</ErrorMessage>}
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='referralPhone'>Referral Phone number</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='referralPhone' value={formState.referralPhone} onChange={updateFormControl} ></ContactFormInfoInput>
                                    {errorFormState.phoneErrorMessage && <ErrorMessage>{errorFormState.phoneErrorMessage}</ErrorMessage>}
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='message'>Notes</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='message' value={formState.message} onChange={updateFormControl} ></ContactFormInfoInput>  
                                </FormColumn>
                            </FormRow>
                        </ContactFormFields>
                        <CaptchaContainer>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={handleCaptcha}
                                ref={captchaRef}
                            />
                        </CaptchaContainer>
                        <ButtonContainer>
                        {!submitting &&
                            <ButtonElement type="submit" disabled={disabledButton || submitting || captcha} onClick={()=>gaEventTracker('submit')}>Submit</ButtonElement>

                        }
                        {submitting &&
                            <ButtonElement>
                                <Oval height='1.5rem'/>
                            </ButtonElement>
                        }
                        </ButtonContainer>
                        {disabledButton && (
                            <RequiredErrorMessage>Referral Email or Phone Required</RequiredErrorMessage>
                        )}
                        
                    </ContactForm>
                </ContactFormWrapper>
            </ContactWrapper>
        </ContactContainer>
  )
}

export default Refer