import React from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

// Replace this with the actual path to your image
import aboutUsImage from '../../images/commercial/commercial_solar.jpeg';

const CustomContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#1BA8F1', // Adjust the color to match the image's background
//   padding: '50px 20px',
  position: 'relative',
});

const Content = styled('div')({
  maxWidth: '800px',
  margin: '50px',
  position: 'relative',
  color: '#ffffff', // Adjust the color to match the image's text color
});

const VerticalBars = styled('div')({
  position: 'absolute',
  top: 0,
  right: '-40px', // Adjusted to make sure it aligns with the edge
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '60%',
  padding: '20px 0',

  '&::before, &::after, div': {
    content: '""',
    width: '5px',
    backgroundColor: '#ffffff',
    margin: '20px 0',
  },

  '&::before': {
    height: '300px', // Large
  },

  div: {
    height: '160px', // Medium
  },

  '&::after': {
    height: '120px', // Small
  },
});

const SmallRectangle = styled('div')({
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    width: '50px',
    height: '80px',
    marginRight: '250px',
    marginBottom: '150px',
    backgroundColor: '#33c0f4', // Slightly lighter color
    opacity: 0.6, // Slightly transparent
  });

const incentives = [
  {
    title: 'INFLATION REDUCTION ACT',
    content: `The Inflation Reduction Act (IRA) benefits the solar industry by extending and enhancing tax credits for solar energy projects. It includes provisions for a 30% investment tax credit (ITC) for solar installations, available for residential and commercial projects. The IRA also introduces incentives for domestic manufacturing of solar components and promotes solar deployment in low-income and disadvantaged communities. These measures are designed to accelerate the adoption of solar energy and support the growth of the renewable energy sector in the United States.`,
  },
  {
    title: 'MACRS DEPRECIATION',
    content: `MACRS stands for Modified Accelerated Cost Recovery system. This depreciation system is used for tax purposes in the U.S., allowing the cost of fixed assets to be recovered over a specified period through annual deductions.`,
  },
  {
    title: '30% INVESTMENT TAX CREDIT',
    content: `Solar photovoltaic (PV) electricity systems qualify for a 30% Renewable Energy Federal Tax Credit from the government, and with our financing options and low energy costs, they become an affordable option. This tax credit has no maximum cap in commercial applications. NPO entities may now receive the equivalent amount as a Federal Direct Pay cash incentive.`,
  },
  {
    title: 'ADDITIONAL GRANTS/INCENTIVES',
    content: `Additional incentives such as Energy Community Bonus and Domestic Content Adder are available based on location and project specifications. These incentives can increase the value of a tax credit beyond 30%.`,
  },
  {
    title: 'CUSTOM FINANCING/LEASING',
    content: `Ask us about our wide assortment of custom financing and leasing options available for every type of customer.`,
  },
];

const FinancialIncentives = () => {
  return (
    <CustomContainer>
      <Content>
        <Typography variant="h3" component="h3" sx={{ color: '#FFA500', textAlign: 'right', marginBottom: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
          FINANCIAL INCENTIVES
        </Typography>
        <Typography variant="h5" sx={{ color: '#fff', marginBottom: '20px', textAlign: 'right' }}>
        Maximize Your Savings with Solar Incentives
      </Typography>
        {incentives.map((incentive, index) => (
          <Accordion key={index} sx={{ marginBottom: '20px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
              <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold', color: '#000', textAlign: 'left' }}>
                {incentive.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p" sx={{ color: '#000', textAlign: 'left', lineHeight: 2.0 }}>
                {incentive.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        <VerticalBars>
          <div></div>
        </VerticalBars>
      </Content>
      {/* <SmallRectangle /> */}
    </CustomContainer>
  );
};

export default FinancialIncentives;
