import React from 'react'
import LeadForm from '../../components/LeadForm'
import Layout from '../../components/Layout'
import { leadObj } from '../../components/LeadForm/data'

const Lead = () => {
  return (
    <div style={{backgroundColor:'#fff'}}>
      <Layout>
        <LeadForm {...leadObj}/>
      </Layout>
    </div>
  )
}

export default Lead