import React, { useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { navObj } from '../Navbar/data';
import { footerObj } from '../Footer/data';

const Layout = ({children}) => {
    const [isopen, setIsOpen] = useState(0);

    const toggle = () => {
      if(isopen === 1) {
        setIsOpen(0)
      }
      else {
        setIsOpen(1)
      }
    }
  return (
    <>
        <Sidebar isopen={isopen} toggle={toggle}/>
        <Navbar toggle={toggle} {...navObj}/>
          <main>{children}</main>
        <Footer {...footerObj}/>
    </>
  )
}

export default Layout