import styled from "styled-components";
import Autocomplete from 'react-google-autocomplete';
import { IoMdLocate } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import TextField from '@mui/material/TextField';

export const ContactContainer = styled.div`
    height: auto;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Avenir_Book';
    background-color: #fff;
    /* @media screen and (max-width: 1050px) {
        height: 2100px;
    }
    @media screen and (max-width: 768px) {
        height: 2100px;
    }

    @media screen and (max-width: 480px) {
        height: 1600px;
    } */

`;

export const HeaderWrapper = styled.div`
    display: grid;
    height: auto;
    width: 50%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: 80px;
    padding: 0 10px;
    padding-top: 80px;
    justify-content: start;
    @media screen and (max-width: 1050px) {
        width: 90%;
        margin-left: auto;
    }
    @media screen and (max-width: 480px) {
        padding-top: 50px;
        height: 180px;
    }

`;

export const HeaderTopLine = styled.p`
    color: #ffa500;
    font-size: 100%;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
        font-size: 75%;
    }
`;

export const Header = styled.h1`
    color: #000;
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    white-space: pre-wrap;

    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`;

export const ContactWrapper = styled.div`
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "col1 col2";
    @media screen and (max-width: 1050px) {
        /* grid-template-columns: 1fr 1fr; */
        grid-template-areas: 'col1 col1' 'col2 col2';
        /* height: auto; */
    }
`;

export const ContactInfoWrapper = styled.div`
    grid-area: col1;
    margin: 30px;
    padding-top: 20px;
    /* @media screen and (max-width: 1050px) {
        height: 300px;
    } */
`;

export const ContactFormWrapper = styled.div`
    grid-area: col2;
    @media screen and (max-width: 1050px) {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        /* height: 700px; */
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

`;

export const ContactInfo = styled.p`
    max-width: 600px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    white-space: pre-wrap;
    font-family: 'Avenir_Book';

    
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const ContactInfoLink = styled.a`
    max-width: 600px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    white-space: pre-wrap;
    font-family: 'Avenir_Book';

    &:hover{
        color: #ffa500;
        transition: 0.3s ease-out;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const ContactForm = styled.form`
    max-width: 1000px;
`;

export const FormRow = styled.div`
    display: grid;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: 1fr 1fr;
    margin: 50px;
    gap: 50px;

    @media screen and (max-width: 1050px) {
        grid-template-columns: 1fr;
    }


`;
export const FormMessageRow = styled.div`
    display: grid;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: 1fr;
    margin: 50px;
    gap: 40px;
`;

export const ContactFormFields = styled.div`

`;

export const FormColumn = styled.div`
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 480px) {
        gap: 20px;
    }
`;

export const ContactFormInfoTitle = styled.label`
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
    font-family: 'Avenir_Book';
    @media screen and (max-width: 480px) {
        font-size: 1.1rem;
    }
`;

export const ContactFormInfoInput = styled.input`
    border: none;
    border-bottom: 1px solid black;
    height: 40px;
`;

export const CustomerAddressWrapper = styled.div`
    /* background-color: green; */
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    /* justify-content: center;
    align-items: center; */
`;

export const ContactFormAutocomplete = styled(Autocomplete)`
    border: none;
    border-bottom: 1px solid black;
    height: 40px;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    z-index: 1;
`;

export const ContactFormAddressInput = styled.input`
    border: none;
    border-bottom: 1px solid black;
    height: 40px;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    z-index: 1;
`;

export const CustomerInputLocateIcon = styled(IoMdLocate)`
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    justify-self: end;
    z-index: 2;
    color: black;
    transform: translateY(2px);
`;

export const CustomerInputDeleteIcon = styled(IoMdClose)`
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    justify-self: end;
    z-index: 2;
    color: black;
    transform: translateY(2px);
`;

export const ButtonContainer = styled.div`
    margin: auto;
    align-items: center;
    width: 200px;
    /* padding-bottom:100px; */
`;

export const SubmitErrorMessage = styled.div.attrs((props: {color: string}) => props)`
    color: ${(props) => props.color};
    font-size: 25px;
    padding: 10px;
    margin: auto;
    text-align: center;
    align-items: center;
    width: 400px;
`;

export const RequiredErrorMessage = styled.div`
    color: #000;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
    font-family: 'Avenir_Book';
`;

export const ErrorMessage = styled.div`
    color: red;
    font-family: 'Avenir_Book';
    margin-top: -20px;
    margin-bottom: -30px;
`;

export const CaptchaContainer = styled.div`
    margin: auto;
    align-items: center;
    width: 300px;
    padding-bottom:5px;
`

export const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      padding: '4px 8px',
    },
  });
