import styled from "styled-components";

const Video = () => {

    const BackgroundWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    `;

    const HiringVideo = styled.video`
    display: block; 
    margin: auto; 
    width: 100%;

    `

  return (
    <>
        <BackgroundWrapper>
            <HiringVideo src={'/videos/lunexxmas.mp4'} type="video/mp4" controls/>
        </BackgroundWrapper>
   
    </>

  )
}

export default Video