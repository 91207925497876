import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Paper, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Logo, StyledContainer, ErrorStyled, TextField, SuccessStyled } from '../NewDeal/NewDealElements';
import styled from 'styled-components';

export const Title = styled.h2`
  /* font-size: 36px; // Mobile-friendly font size */
  color: #333; // Dark color for text
  margin-bottom: 15px; // Space below the title
  font-family: 'Avenir_Black';
`;

const OnboardingVerify = ({img, alt}) => {

    const [salesRepName, setSalesRepName] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState('');

    const [salesRep, setSalesRep] = useState({
        redlineFL: '2.4',
        redlineNE: '3.0',
    });

    // Use useParams to extract the user ID from the URL path
    const { id } = useParams();

    useEffect(() => {
        const fetchSalesRep = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/fetchSalesRep/${id}`);
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message || 'Network response was not ok');
                }
                const data = await response.json();
                console.log("data", data);
                setSalesRepName(data.salesRepName);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Failed to fetch sales rep:', error);
            }
        };
    
        fetchSalesRep();
    }, [id]);
    

    const verifyNow = async () => {
        setSubmitting(true);
        setSubmitErrorMessage('');

        const { redlineFL, redlineNE } = salesRep;
        // Send confirmation to the backend
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/createSalesRepAfterApproval`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    managerSecurityId: id,
                    redlineFL,
                    redlineNE
                }),
            });
            if(response.status === 0){
                console.log("Network error occurred");
                setSubmitErrorMessage("Network error occurred. Please check your internet connection.");
                throw new Error('Network error occurred');
            }
            if (!response.ok) {
                console.log("Response not ok");
                setSubmitErrorMessage("Submission failed");
                throw new Error('Network response was not ok');
            }
            setSubmitting(false);
            setSuccess(true);
        } catch (error) {
            console.error('Failed to send Verification:', error);
            setSubmitErrorMessage("Submission failed");
        }

        setSubmitting(false);
    };

    const handleChange = (field, value) => {
        if ((field === 'redlineFL' || field === 'redlineNE') && !/^\d{0,1}(\.\d{0,2})?$/.test(value)) {
            return; // Don't update state if non-numeric characters are entered
        }
        setSalesRep({ ...salesRep, [field]: value });
      };


    return (
        <StyledContainer>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
                <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                {loading &&
                    <Title>
                        Loading...
                    </Title>
                }
                {!loading && 
                    <>
                        <Logo src={img} alt={alt} style={{ width: '200px', height: 'auto' }} />
                        <Title>
                            Verification Form
                        </Title>
                        <Typography variant="subtitle1" textAlign="center">
                            Please verify {salesRepName}'s redlines.
                        </Typography>
                        <TextField
                            label="Redline FL"
                            value={salesRep.redlineFL}
                            onChange={(e) => handleChange('redlineFL', e.target.value)}
                            inputProps={{ inputMode: 'numeric' }}
                            required 
                        />
                        <TextField
                            label="Redline NE"
                            value={salesRep.redlineNE}
                            onChange={(e) => handleChange('redlineNE', e.target.value)}
                            inputProps={{ inputMode: 'numeric' }}
                            required 
                        />
                        <Button variant="contained" style={{minWidth: '155px'}} color="secondary" onClick={verifyNow} disabled={submitting}>
                            {submitting ? <CircularProgress size={24} /> : 'Verify'}
                        </Button>
                        {submitErrorMessage &&
                            <ErrorStyled>
                                {submitErrorMessage}
                            </ErrorStyled>
                        }
                        {success &&
                            <SuccessStyled>Success</SuccessStyled>
                        }
                    </>
                }
                </Paper>
            </Box>
        </StyledContainer>
    );
};

export default OnboardingVerify;
