import styled from 'styled-components';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { TextField } from '../NewDeal/NewDealElements';

export const StyledDatePicker = styled(({ className, ...props }) => (
    <MuiDatePicker {...props} TextFieldComponent={TextField} className={className} slotProps={{
      textField: {
        required: true,
      },
    }}/>
  ))`
    && {
      margin: 10px 0; // Adds margin around the date picker for better spacing
      width: 100%; // Ensures the date picker takes up the full width of the container
    }
  `;