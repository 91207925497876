import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

// Replace this with the actual path to your image
import aboutUsImage from '../../images/commercial/bancroft.webp'

const CustomContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#1BA8F1',
    // margin: '50px'
    // padding: '50px 20px',
});

const AboutContent = styled('div')({
  maxWidth: '800px',
  margin: '50px',
  position: 'relative',
  color: '#ffffff', // Adjust the color to match the image's text color
});

const AboutImage = styled('img')({
  width: '100%',
  maxWidth: '1000px',
  borderRadius: '10px',
  marginTop: '20px',
});

const VerticalBars = styled('div')({
  position: 'absolute',
  top: 0,
  right: '-40px', // Adjusted to make sure it aligns with the edge
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '45%',
  padding: '20px 0',

  '&::before, &::after, div': {
    content: '""',
    width: '5px',
    backgroundColor: '#ffffff',
    margin: '20px 0',
  },

  '&::before': {
    height: '300px', // Large
  },

  div: {
    height: '160px', // Medium
  },

  '&::after': {
    height: '120px', // Small
  },
});

const AboutUs = () => {
  return (
    <CustomContainer>
      <AboutContent>
        <Typography variant="h3" component="h3" sx={{ color: '#FFA500', textAlign: 'left', marginBottom: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'  }}>
          ABOUT SOLAR
        </Typography>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', color: '#000', textAlign: 'left', marginBottom: '20px' }}>
          CLEAN. AFFORDABLE. RELIABLE.
        </Typography>
        <Typography variant="subtitle1" component="p" sx={{ color: '#ffffff', textAlign: 'left', marginBottom: '20px', lineHeight: 2.0 }}>
          At Lunex Power, we believe in power by choice, not by chance. This means every business deserves the opportunity to choose their energy future based on what makes sense for them, instead of being confined to traditional utility options dictated by location. With our turnkey solar installation services, we make it easy for businesses to transition to solar energy, tailored to their specific needs. Take control of your power with Lunex Power and make the switch to solar without compromise.
        </Typography>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', color: '#000', textAlign: 'left', marginBottom: '20px' }}>
          Building a Better Future
        </Typography>
        <Typography variant="subtitle1" component="p" sx={{ color: '#ffffff', textAlign: 'left', marginBottom: '20px', lineHeight: 2.0 }}>
          Business leaders and stakeholders have a significant role in shaping a smarter, more resilient, and environmentally conscious future for their cities and beyond. By embracing sustainable practices and clean energy solutions, companies can lead by example and positively impact their reputation and community standing.
        </Typography>
        <AboutImage src={aboutUsImage} alt="About Us" />
        <VerticalBars>
          <div></div>
        </VerticalBars>
      </AboutContent>
    </CustomContainer>
  );
};

export default AboutUs;
