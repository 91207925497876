import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import contactImage from '../../images/commercial/roof.webp'; // Replace with your image path

const CustomContainer = styled(Box)({
  // width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff', // Adjust the color to match the image's background
  padding: '50px 20px',
});

const Content = styled(Box)({
  maxWidth: '1000px',
  width: '100%',
  color: '#ffffff', // Adjust the color to match the image's text color
  textAlign: 'center',
});

const ImageWrapper = styled(Box)({
  width: '100%',
  height: '0',
  paddingBottom: '30%', // Maintain a 16:9 aspect ratio
  overflow: 'hidden',
  position: 'relative',
  marginBottom: '40px', // Add margin only to the bottom
});

const SlicedImage = styled('img')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100%',
  height: 'auto',
  transform: 'translate(-50%, -50%)',
  objectFit: 'cover',
  objectPosition: 'center',
  clipPath: 'inset(20% 0 20% 0)', // This will clip the top and bottom 20% of the image
});

const contactDetails = [
  {
    title: 'Office',
    phone: '(813) 540-8807',
    email: 'info@lunexpower.com',
  },
  {
    title: 'Bob Bruno',
    position: 'VP, Utility',
    phone: '(908) 963-2332',
    email: 'bob@lunexpower.com',
  },
  {
    title: 'Jax Phipps',
    position: 'Manager, Commercial Sales',
    phone: '(941) 345-2360',
    email: 'jax@lunexpower.com',
  },
  {
    title: 'Dan Gawrych',
    position: 'CEO, Founder',
    phone: '(813) 638-5178',
    email: 'dan@lunexpower.com',
  },
];

const ContactUs = () => {
  return (
    <CustomContainer>
      <Content>
        <Typography variant="h3" component="h3" sx={{ color: '#FFA500', marginBottom: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
          LET'S WORK TOGETHER.
        </Typography>
        <ImageWrapper>
          <SlicedImage src={contactImage} alt="Contact Us" />
        </ImageWrapper>
        <Typography variant="h4" component="h3" sx={{ color: '#FFA500', marginBottom: '20px' }}>
          CONTACT US
        </Typography>
        <Grid container spacing={4} sx={{ textAlign: 'center', color: '#000' }}>
          {contactDetails.map((contact, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Typography variant="h6" component="h4" sx={{ fontWeight: 'bold', color: '#000' }}>
                {contact.title}
              </Typography>
              {contact.position && (
                <Typography variant="subtitle1" component="p" sx={{ fontStyle: 'italic', color: '#000' }}>
                  {contact.position}
                </Typography>
              )}
              <Typography variant="body1" component="p">
                {contact.phone}
              </Typography>
              <Typography variant="body1" component="p">
                {contact.email}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Content>
    </CustomContainer>
  );
};

export default ContactUs;
