import styled from "styled-components";
import {Link} from 'react-scroll';

export const Button = styled(Link)`
    border-radius: 50px;
    border: 2px solid white;
    background: ${({primary}) => (primary ? '#1BA8F1' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir_Book';

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#ffa500' : '#ffa500')};
        color: #fff
    }

`;

export const ButtonSubmit = styled.input.attrs(({props,buttonText}) => ({
    type: 'submit',
    value: buttonText
  }))`
    border-radius: 50px;
    border: 2px solid white;
    background: #1BA8F1;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir_Book';
    margin-left:auto;
    margin-right: auto;

    &:disabled {
        background: #808080;
        pointer-events: none
    }

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#ffa500' : '#ffa500')};
        color: #fff
    }

    

`;

