import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import roof from '../../images/commercial/roof_mount.webp';
import carport from '../../images/commercial/carport.webp';
import solarFarm from '../../images/commercial/solar_farm.webp';
import evCharger from '../../images/commercial/ev_charger.webp';

const Container = styled.div`
  margin: 50px;
  text-align: center;
`;

const SolutionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SolutionCard = styled.div`
  position: relative;
  display: block;
  min-width: 230px;
  max-width: 300px;
  width: 23%;
  height: 400px;
  margin: 12px;
  text-decoration: none;
  color: white;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1200px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

const SolutionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7);
  border-radius: 5px;
`;

const SolutionLabel = styled(Typography)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  font-size: 1.2em;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
`;

const ExpandedImageContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  /* right: 20px; */
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  max-width: 1000px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 10px;
  overflow: hidden;

  /* @media (max-width: 768px) {
    width: 85%;
    transform: translate(-50%, -50%);
  } */

`;

const ExpandedImageWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const ExpandedImage = styled.img`
  width: 100%;
  height: 100%;
  /* filter: brightness(0.7); */
  object-fit: cover;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  /* margin: 10px; */
  color: white !important;
  background-color: grey !important;
  z-index: 2000;
  &:hover {
    background-color: darkgrey !important;
  }
`;

const ExpandedText = styled(Typography)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  color: white;
  font-size: 1.5em;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;

`;

const Solutions = () => {
  const [expandedImage, setExpandedImage] = useState(null);
  const [expandedText, setExpandedText] = useState('');

  const textContent = {
    'Rooftop Solar': (
      <>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
          ROOF MOUNTS
        </Typography>
        <Typography variant="body1" component="span">
          Solar roofs are a cost-effective renewable energy solution for commercial buildings in Florida. Lunex Power designs and installs solar panels on commercial roofs, particularly in high-wind areas. Our expertise in commercial roofing ensures that our solar systems withstand Florida's challenging climate. Partnering with Lunex Power means benefiting from over 50 years of combined experience and thousands of happy customers and reducing the risks of rooftop solar installations. Solar roofs provide numerous benefits, such as tax incentives, lower electricity bills, increased property value, extended roof lifespan, and improved sustainability.
        </Typography>
      </>
    ),
    'Ground Mounts': (
      <>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
          GROUND MOUNTS
        </Typography>
        <Typography variant="body1" component="span">
          Ground-mount solar farms are an excellent choice for generating renewable energy where land is available. Lunex Power designs, procures, and constructs solar farms and large ground-mount installations in Florida. With extensive experience in large-scale projects and remote areas, we ensure efficient and reliable service. Our streamlined process, using in-house divisions for solar design, metal fabrication, and construction, maintains high standards of quality and safety. Solar farms offer benefits like tax incentives, revenue from underutilized land, increased property value, minimal environmental impact, and reduced carbon footprint.
        </Typography>
      </>
    ),
    'Solar Carports': (
      <>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
          CARPORTS
        </Typography>
        <Typography variant="body1" component="span">
          As energy prices rise and Florida's weather damages vehicles, the demand for safe parking solutions grows. Solar carports protect vehicles and generate renewable energy, ideal for businesses facing these issues. Lunex Power designs, procures, and constructs commercial solar carports, particularly in high-wind areas. We manage every aspect of your project, ensuring a seamless process. Solar carports offer numerous benefits, including tax incentives, revenue generation from parking, lower electricity costs, increased property value, weather protection, and enhanced sustainability.
        </Typography>
      </>
    ),
    'EV Charging': (
      <>
        <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
          EV (ELECTRONIC VEHICLE) CHARGING
        </Typography>
        <Typography variant="body1" component="span">
          Electric Vehicle (EV) Charging Stations not only enhance property value but also cater to the increasing demand for EV charging. EV Charging Stations provide a range of benefits, such as the capability to charge any electric vehicle on the road, attracting new customers, employees, tenants, and visitors. They also help establish your brand as a leader in sustainability, with features including remote monitoring and updates to maintain station functionality, real-time status updates, and a mobile app to engage drivers. Moreover, there are financial incentives available to support the installation and operation of these stations.
        </Typography>
      </>
    ),
  };

  const handleImageClick = (image, text, event) => {
    event.preventDefault(); // Prevent the default link behavior
    setExpandedImage(image);
    setExpandedText(textContent[text]);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const handleCloseClick = () => {
    setExpandedImage(null);
    setExpandedText('');
    document.body.style.overflow = 'auto'; // Enable scrolling
  };

  return (
    <Container>
      <Typography variant="h2" component="h3" sx={{ color: '#FFA500', marginBottom: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
        SOLUTIONS
      </Typography>
      <Typography variant="h5" component="div" sx={{ color: '#000', marginBottom: '40px' }}>
        More Than 22+ Megawatts Installed
      </Typography>
      <SolutionsContainer>
        <SolutionCard onClick={(event) => handleImageClick(roof, 'Rooftop Solar', event)}>
          <SolutionImage src={roof} alt="Rooftop Solar" />
          <SolutionLabel variant="h5" component="div">Rooftop Solar</SolutionLabel>
        </SolutionCard>
        <SolutionCard onClick={(event) => handleImageClick(solarFarm, 'Ground Mounts', event)}>
          <SolutionImage src={solarFarm} alt="Ground Mounts" />
          <SolutionLabel variant="h5" component="div">Ground Mounts</SolutionLabel>
        </SolutionCard>
        <SolutionCard onClick={(event) => handleImageClick(carport, 'Solar Carports', event)}>
          <SolutionImage src={carport} alt="Solar Carports" />
          <SolutionLabel variant="h5" component="div">Solar Carports</SolutionLabel>
        </SolutionCard>
        <SolutionCard onClick={(event) => handleImageClick(evCharger, 'EV Charging', event)}>
          <SolutionImage src={evCharger} alt="EV Charging" />
          <SolutionLabel variant="h5" component="div">EV Charging</SolutionLabel>
        </SolutionCard>
      </SolutionsContainer>
      {expandedImage && (
        <ExpandedImageContainer>
          <CloseButton onClick={handleCloseClick}>
            <CloseIcon />
          </CloseButton>
          <ExpandedImageWrapper>
            <ExpandedImage src={expandedImage} alt="Expanded View" />
          </ExpandedImageWrapper>
          <ExpandedText>
            {expandedText}
          </ExpandedText>
        </ExpandedImageContainer>
      )}
    </Container>
  );
};

export default Solutions;

