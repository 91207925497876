import { useState } from "react";
import { FormControl } from "../pages/NewDeal/NewDealElements";
import { IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Checkbox, ListItemText } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
}

export function ClearableSelect({ name, options, value, onChange, label }) {
  const [open, setOpen] = useState(false);

  const handleClear = () => {
      onChange({ target: { name, value: '' } }); 
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        onChange={(e) => onChange(e)}
        name={name}
        label={label}
        input={
          <OutlinedInput
            label={label}
            endAdornment={
              value && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear selection"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent Select from opening
                      handleClear();
                    }}
                    onMouseDown={(e) => e.preventDefault()} // Prevent focus state change
                    style={{ marginRight: '12px' }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function MultiSelect({ name, options, value, onChange, label }) {
  const [open, setOpen] = useState(false);

  const isObject = typeof options[0] === 'object';

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        onChange={(e) => onChange(e)}
        name={name}
        label={label}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((option) => (
          <MenuItem key={isObject ? option.value : option} value={isObject ? option.value : option}>
            <Checkbox checked={value.indexOf(isObject ? option.value : option) > -1} />
            <ListItemText primary={isObject ? option.label : option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
