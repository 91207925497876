import React from 'react'
import Contact from '../../components/ContactSection'
import Layout from '../../components/Layout'
import { contactObj } from '../../components/ContactSection/data'

const ContactPage = ({urlPath}) => {
  return (
    <div style={{backgroundColor:'#fff'}}>
      <Layout>
        <Contact {...contactObj} urlPath={urlPath}/>
      </Layout>
    </div>
  )
}

export default ContactPage