import styled from "styled-components";

export const ContactFormWrapper = styled.div`
    /* grid-area: col2; */
    /* justify-items: center; */
    margin-left: auto;
    margin-right: auto;
    width:70%;
    @media screen and (max-width: 1050px) {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        height: 700px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

`;

export const ContactInfo = styled.p`
    max-width: 600px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    white-space: pre-wrap;
    font-family: 'Avenir_Book';

    
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const ContactInfoLink = styled.a`
    max-width: 600px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    white-space: pre-wrap;
    font-family: 'Avenir_Book';

    &:hover{
        color: #ffa500;
        transition: 0.3s ease-out;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const ContactForm = styled.form`
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
`;

export const FormRow = styled.div`
    display: grid;
    /* justify-items: center; */
    /* margin-left: auto;
    margin-right: auto; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    grid-template-columns: 1fr 1fr;
    margin: 50px;
    gap: 50px;

    @media screen and (max-width: 1050px) {
        grid-template-columns: 1fr;
    }


`;
export const FormMessageRow = styled.div`
    display: grid;
    /* margin-left: 0;
    margin-right: 0; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    grid-template-columns: 1fr;
    margin: 50px;
    gap: 40px;
`;

export const ContactFormFields = styled.div`

`;

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 480px) {
        gap: 20px;
    }
`;

export const ContactFormInfoTitle = styled.label`
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
    font-family: 'Avenir_Book';
    @media screen and (max-width: 480px) {
        font-size: 1.1rem;
    }
`;

export const ContactFormInfoInput = styled.input`
    border: none;
    border-bottom: 1px solid black;
    height: 30px;
`;

export const ButtonContainer = styled.div`
    /* margin: auto; */
    /* align-items: center; */
    width: 200px;
    margin-left: auto;
    margin-right: auto;
`;

export const SubmitErrorMessage = styled.div.attrs((props: {color: string}) => props)`
    color: ${(props) => props.color};
    font-size: 25px;
    padding: 10px;
    margin: auto;
    text-align: center;
    align-items: center;
    width: 400px;
`;

export const RequiredErrorMessage = styled.div`
    color: #000;
    margin-left: auto;
    margin-right: auto;

    font-size: 14px;
    text-align: center;
    font-family: 'Avenir_Book';
`;

export const ErrorMessage = styled.div`
    color: red;
    font-family: 'Avenir_Book';
    margin-top: -20px;
    margin-bottom: -30px;
`;

export const ButtonElement = styled.button`
    border-radius: 50px;
    border: 2px solid white;
    background: #1BA8F1;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir_Book';
    margin-left: auto;
    margin-right: auto; 
    text-decoration: none !important;
    &:disabled {
        background: #808080;
        pointer-events: none;
    }
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffa500;
        color: #fff;
    }
`;