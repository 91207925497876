import React, { lazy, Suspense } from 'react'
import HeroSection from '../components/HeroSection';
import { homeObjOne, homeObjThree, homeObjTwo } from '../components/InfoSection/data';
import { homeObjFour } from '../components/InfoSection2/data';
import { contactObj } from '../components/ContactSection/data';
import { CustomerMapObj } from '../components/CustomerMap/data';
import { CollabObj } from '../components/CollabSection/data';

import Layout from '../components/Layout';
import { Oval } from 'react-loading-icons';
import styled from 'styled-components';
import { BannerWrapper, BatteryImg, BannerContainer } from '../components/CollabSection/CollabElements';

const InfoSection = lazy(() => import('../components/InfoSection'));
const InfoSection2 = lazy(() => import('../components/InfoSection2'));
const Services = lazy(() => import('../components/Services'));
const Reviews = lazy(() => import('../components/Reviews'));
const Collab = lazy(() => import('../components/CollabSection'));
const CustomerMap = lazy(() => import('../components/CustomerMap'));
const Contact = lazy(() => import('../components/ContactSection'));

const Home = () => {

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  `;

  return (
    <>
      <Layout>
        <HeroSection />
        <Suspense fallback={<LoadingContainer><Oval /></LoadingContainer>}>
          <BannerContainer>
            <BannerWrapper>
              <BatteryImg src={require("../images/collab/banner.webp")} alt={"Official Solar Company of USF Athletics & Tampa Bay Rays"} />
            </BannerWrapper>
          </BannerContainer>
          <InfoSection {...homeObjOne}/>
          <InfoSection {...homeObjTwo}/>
          <InfoSection {...homeObjThree}/>
          <InfoSection2 {...homeObjFour}/>
          <Services />
          <Reviews />
          <Collab {...CollabObj}/>
          <CustomerMap {...CustomerMapObj}/>
          <Contact {...contactObj}/>
        </Suspense>
      </Layout>
    </>
  )
}

export default Home