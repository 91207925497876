import React from 'react'
import styled from 'styled-components'
import hero_img from '../../images/Images/Homepage/MainBanner.webp'
import Layout from '../../components/Layout';

const NotFound = () => {
    const NotFoundWrapper = styled.div`
        background: #0c0c0c;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        height: 100vh;
        position: relative;
        z-index: 1;
        margin-top: -80px;
        font-family: 'Avenir_Book';
    `;

    const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    `;

    const ImageBg = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${hero_img});
    background-size: cover;
    `;

    const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -300px;

    @media screen and (max-width: 900px) {
        margin-left: 0;
    }
    `

    const HeroH1 = styled.h1`
    color: #fff;
    font-size: 48px;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
    `

    const HeroBtnWrapper = styled.div`
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    const Button = styled.a`
        border-radius: 50px;
        border: 2px solid white;
        background: ${({primary}) => (primary ? '#1BA8F1' : '#010606')};
        white-space: nowrap;
        padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
        color: ${({dark}) => (dark ? '#010606' : '#fff')};
        font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
        font-family: 'Avenir_Book';

        &:hover {
            transition: all 0.2s ease-in-out;
            background: ${({primary}) => (primary ? '#ffa500' : '#ffa500')};
            color: #fff
        }
    `

  return (
    <>
        <Layout>
            <NotFoundWrapper>
                <HeroBg>
                <ImageBg />
            </HeroBg>
            <HeroContent>
            <HeroH1>Oops! You have found a page that can't be found</HeroH1>
            <HeroBtnWrapper>
                <Button to='/' href='/' primary='true'>
                    Go Home
                </Button>
                    

            </HeroBtnWrapper>
            </HeroContent>
                {/* <NotFoundTitle>
                    <a to='/' href='/'>Go Home</a>
                </NotFoundTitle> */}
                
            </NotFoundWrapper>
        </Layout>
    </>
  )
}

export default NotFound