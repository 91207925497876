import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { ErrorStyled, Logo, StyledContainer, SuccessStyled, TextField, Title } from '../NewDeal/NewDealElements';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ClearableSelect } from '../../helpers/helperFunctions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledDatePicker } from './OnboardingElements';
import { pdfjs } from 'react-pdf';
import AddressAutocomplete from '../../components/AddressAutocomplete';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const OnboardingForm = ({img, alt, urlPath}) => {
    const [salesRep, setSalesRep] = useState({
        name: '',
        address: '',
        phone: '',
        email: '',
        dob: null,
        ssn: '',
        filingStatus: '',
        numOfDependents: '',
        location: '',
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [showConsent, setShowConsent] = useState(false);

  const locationOptions = ["Tampa", "Orlando", "NE", "Space Coast"];
  const filingStatusOptions = ["Head Of Household", "Single", "Married"];

  useEffect(() => {
    const isFormValid = () => {
      const { name, address, phone, email, dob, ssn, filingStatus, numOfDependents, location } = salesRep;
      return !!name && !!address && !!phone && !!email && !!dob && !!ssn && !!filingStatus && !!numOfDependents && !!location;
    };

    const formIsValid = isFormValid();
    setFormValid(formIsValid);
  }, [salesRep]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validation
    const { name, address, phone, email, dob, ssn, filingStatus, numOfDependents, location } = salesRep;

    // Trim white spaces from the name field
    const trimmedName = name.trim();

    if (!trimmedName || !address || !phone || !email || !dob || !ssn || !filingStatus || !numOfDependents || !location) {
      setError('All fields are required');
      return;
    }

    // Backend submission
    setLoading(true);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/` + urlPath, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name:trimmedName,
            address,
            phone,
            email,
            dob,
            ssn,
            filingStatus, 
            numOfDependents,
            location,
          }),
        });
    
        if (response.ok) {
          setSuccess('Thank you! You will receive an email for verification and further steps shortly.');
          setSalesRep({
            name: '',
            address: '',
            phone: '',
            email: '',
            dob: null,
            ssn: '',
            filingStatus: '',
            numOfDependents: '',
            location: '',
        });
        } else if (response.status === 409){
          console.log("Network error occurred");
          setError("This email has already signed up");
          throw new Error('Network error occurred');
        } else {
          setError('Submission failed');
        }

      } catch (error) {
        console.log("An error occurred");
        // setError('Submission failed');
      }
      setLoading(false);
    };

  const handleChange = (field, value) => {
    if ((field === 'ssn' || field === 'phone' || field === 'numOfDependents') && !/^\d*$/.test(value)) {
        return; // Don't update state if non-numeric characters are entered
    }
    setSalesRep({ ...salesRep, [field]: value });
  };

  const handleNextClick = () => {
    setShowConsent(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledContainer onSubmit={handleSubmit}>
            <Logo src={img} alt={alt} />
            <Title>Onboarding Form</Title>
        
            {showConsent ? (
              <>
                <br />
                <Typography variant="h6" textAlign="center">
                  Electronic Transactions Consent Agreement
                </Typography>
                <br />
                <Typography variant="body1" textAlign="center">
                  By clicking the 'Agree and Submit' button below, you are expressly agreeing to conduct all transactions with us electronically. This consent includes receiving, signing, and submitting all relevant documents in electronic format using a self-signed digital signature. Our commitment is to ensure your understanding and comfort with electronic communications throughout our dealings.
                </Typography>
                <br />
                <Typography variant="body1" textAlign="center">
                  Please make certain you have the appropriate technology to access and retain these electronic documents. Should you decide at any point that you wish to withdraw your consent for electronic transactions and revert to paper-based documents, or if you have any reservations about proceeding electronically, you are encouraged to contact us directly at info@lunexpower.com.
                </Typography>
                <br />
                <Typography variant="body1" textAlign="center">
                  We prioritize your confidence and satisfaction in our processes and are here to support any questions or concerns you might have. By proceeding with 'Agree and Submit,' you consent to our electronic onboarding process and will promptly receive the necessary documents for your electronic signature.
                </Typography>
                {/* <Typography variant="body1" textAlign="center">
                  By clicking the 'Agree and Submit' button below, you are expressly agreeing to conduct all transactions with us electronically. This consent includes receiving, signing, and submitting all relevant documents in electronic format. Our commitment is to ensure your understanding and comfort with electronic communications throughout our dealings. Please make certain you have the appropriate technology to access and retain these electronic documents. Should you decide at any point that you wish to withdraw your consent for electronic transactions and revert to paper-based documents, or if you have any reservations about proceeding electronically, you are encouraged to contact us directly at info@lunexpower.com. We prioritize your confidence and satisfaction in our processes and are here to support any questions or concerns you might have. By proceeding with 'Agree and Submit,' you consent to our electronic onboarding process and will promptly receive the necessary documents for your electronic signature.
                </Typography> */}
                
                {error && <><br /><ErrorStyled>{error}</ErrorStyled><br /></>}
                {success && <><br /><SuccessStyled>{success}</SuccessStyled><br /></>}
                {!success &&
                <>
                  <br />
                  <Button onClick={() => setShowConsent(false)}>Back</Button>
                  <br />
                  <Button type="submit" variant="contained" disabled={loading || !formValid}>
                    {loading ? <CircularProgress size={24} /> : 'Agree and Submit'}
                  </Button>
                </>
                }
                
              </>
            ) : (
              <>
                <TextField 
                  label="Name/Company Name" 
                  value={salesRep.name} 
                  onChange={(e) => handleChange('name', e.target.value)}
                  inputProps={{ maxLength: 256 }}
                  required
                  // error={!nameValid}
                  // helperText={!nameValid && 'Please enter a valid first and last name'}
                />
                <AddressAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                    onChange={(e) => handleChange('address', e.target.value)}
                    formState={salesRep}
                    setFormState={setSalesRep}

                />
                <TextField
                    label="Phone"
                    value={salesRep.phone}
                    onChange={(e) => handleChange('phone', e.target.value)}
                    inputProps={{ maxLength: 11, inputMode: 'numeric' }}
                    required 
                />
                <TextField 
                  label="Email" 
                  value={salesRep.email} 
                  onChange={(e) => handleChange('email', e.target.value)}
                  inputProps={{ maxLength: 256 }}
                  required 
                />
                <StyledDatePicker
                    label="Date of Birth"
                    value={salesRep.dob}
                    onChange={(newValue) => handleChange('dob', newValue)}
                />
                <TextField 
                    label="Social Security Number/EIN" 
                    value={salesRep.ssn} 
                    onChange={(e) => handleChange('ssn', e.target.value)}
                    inputProps={{ maxLength: 9, inputMode: 'numeric' }}
                    required 
                />
                <ClearableSelect
                    key="filingStatus"
                    name="filingStatus"
                    label="Filing Status *"
                    value={salesRep.filingStatus}
                    onChange={(newValue) => handleChange('filingStatus', newValue.target.value)}
                    options={filingStatusOptions.map(option => ({ value: option, label: option }))}
                    required
                />
                <TextField 
                    label="Number of Dependents" 
                    value={salesRep.numOfDependents} 
                    onChange={(e) => handleChange('numOfDependents', e.target.value)}
                    inputProps={{ maxLength: 2, inputMode: 'numeric' }}
                    required 
                />

                <ClearableSelect
                    key="location"
                    name="location"
                    label="Location *"
                    value={salesRep.location}
                    onChange={(newValue) => handleChange('location', newValue.target.value)}
                    options={locationOptions.map(option => ({ value: option, label: option }))}
                    required
                />
                <Button onClick={handleNextClick} variant="contained" disabled={loading || !formValid}>Next</Button>
              </>
            )}
            
        </StyledContainer>
    </LocalizationProvider>
  );
};

export default OnboardingForm;
