import React from 'react';
import styled from 'styled-components';
import hero_img from '../../images/commercial/commercial_solar.webp'

const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
    margin-top: -80px;
    font-family: 'Avenir_Book';
`;

const Logo = styled.img`
    width: 80%;
    max-width: 800px;
    margin-bottom: 20px;
`;

const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ImageBg = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${hero_img});
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    filter: brightness(0.7);
`;

const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* margin-left: -300px; */

    @media screen and (max-width: 900px) {
        margin-left: 0;
    }
`

const HeroH1 = styled.h1`
    color: #fff;
    font-size: 48px;
    text-align: left;
    font-style: italic;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: left;
    max-width: 600px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

const LogoImg = require("../../images/commercial/logo.png");

const HeroPage = () => {
  return (
    <HeroContainer>
        <HeroBg>
            <ImageBg />
        </HeroBg>
        <HeroContent>
            <Logo src={LogoImg} alt="Lunex Power Logo" />
            <HeroH1>SOLAR SOLUTIONS</HeroH1>
            <HeroP>
            FOR SMART CITIES AND PRIVATE INDUSTRY
            </HeroP>
        </HeroContent>
    </HeroContainer>
  );
};

export default HeroPage;
