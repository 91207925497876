import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, AccordionSummary, AccordionDetails, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText, TextField, Button, IconButton } from '@mui/material';
import { StyledContainer, Logo, Title, CustomerData, StyledAccordion, ErrorStyled, SuccessStyled, RoundButton } from '../NewDeal/NewDealElements';
import { useParams } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import { ClearableSelect } from '../../helpers/helperFunctions.js';
import { MultiSelect } from '../../helpers/helperFunctions.js';
import { ImageUpload } from '../NewDeal/ImageUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { MediaUpload } from './MediaUpload';

const SatisfactionSurvey = ({ survey, onSurveyChange, customer }) => {
    const ratingFields = [
      { name: 'salesPerson', label: 'Sales Rep' },
      { name: 'officeSupport', label: 'Office Support' },
      { name: 'installationTeam', label: 'Installation Team' },
      { name: 'overall', label: 'Overall' },
    ];
  
    const handleRatingChange = (name, rating) => {
      onSurveyChange({ target: { name, value: rating } });
    };
  
    return (
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Satisfaction Survey</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ClearableSelect
            name="surveyor"
            label="Surveyor Name"
            value={survey.surveyor}
            onChange={onSurveyChange}
            options={[{ value: 'Wilfredo Garcia', label: 'Wilfredo Garcia' }]}
          />
        <MultiSelect
            name="typeOfWork"
            label="Type of Work"
            value={survey.typeOfWork || []}
            onChange={onSurveyChange}
            options={["Solar", "Battery", "Roof", "Electrical"]}
        />
          {ratingFields.map((field) => (
            <div key={field.name}>
              <Typography variant="subtitle1">{field.label}</Typography>
              <div>
                {[0, 1, 2, 3, 4, 5].map((rating) => (
                  <RoundButton
                    key={rating}
                    className={survey[field.name] === rating ? 'selected' : 'notSelected'}
                    onClick={() => handleRatingChange(field.name, rating)}
                  >
                    {rating}
                  </RoundButton>
                ))}
              </div>
            </div>
          ))}
          <TextField
            fullWidth
            margin="normal"
            name="improvement"
            label="How can we improve?"
            type="text"
            value={survey.improvement}
            variant="outlined"
            onChange={onSurveyChange}
            multiline
            rows={4}
            sx={{backgroundColor:'#fff'}}
          />
          <TextField
            fullWidth
            margin="normal"
            name="anythingElse"
            label="Anything Else?"
            type="text"
            value={survey.anythingElse}
            variant="outlined"
            onChange={onSurveyChange}
            sx={{backgroundColor:'#fff'}}
          />
          <ClearableSelect
            name="solarWorking"
            label="Solar Working?"
            value={survey.solarWorking}
            onChange={onSurveyChange}
            options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }, { value: 'Needs Service', label: 'Needs Service' }]}
          />
        </AccordionDetails>
      </StyledAccordion>
    );
  };

  const ReferralSection = ({ referrals, onReferralChange, addReferral, removeReferral }) => {
    return (
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Referrals</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {referrals.map((referral, index) => (
            <div key={index} style={{ position: 'relative', marginBottom: '16px' }}>
              <Typography variant="subtitle1">Referral #{index + 1}</Typography>
              <IconButton
                aria-label="delete"
                onClick={() => removeReferral(index)}
                style={{ position: 'absolute', right: 0, top: 0 }}
              >
                <DeleteIcon />
              </IconButton>
              <TextField
                fullWidth
                margin="normal"
                name="name"
                label="Name"
                type="text"
                value={referral.name}
                variant="outlined"
                onChange={(e) => onReferralChange(index, e)}
                sx={{ backgroundColor: '#fff' }}
              />
              <TextField
                fullWidth
                margin="normal"
                name="phone"
                label="Phone"
                type="text"
                value={referral.phone}
                variant="outlined"
                onChange={(e) => onReferralChange(index, e)}
                sx={{ backgroundColor: '#fff' }}
              />
              <TextField
                fullWidth
                margin="normal"
                name="email"
                label="Email"
                type="email"
                value={referral.email}
                variant="outlined"
                onChange={(e) => onReferralChange(index, e)}
                sx={{ backgroundColor: '#fff' }}
              />
            </div>
          ))}
          <Button variant="contained" onClick={addReferral}>Add Referral</Button>
        </AccordionDetails>
      </StyledAccordion>
    );
  };

function SurveyForm({ img, alt }) {
    const { id } = useParams();
    const [customer, setCustomer] = useState({
        name: '',
        address: '',
        phone: '',
        email: '',
        salesRep: '',
        systemSize: ''
    });

    const [satisfactionSurvey, setSatisfactionSurvey] = useState({
        typeOfWork: [],
        surveyor: '',
        salesRep: null,
        officeSupport: null,
        installationTeam: null,
        overall: null,
        improvement: '',
        anythingElse: '',
        solarWorking: ''
    });

    const [reviews, setReviews] = useState({
        amount: '',
        reviewImages: [],
        droneImages: [],
        videoTestimonial: [],
        additionalImages: []
    });

    const [referrals, setReferrals] = useState([{ name: '', phone: '', email: '' }]);
    const [isLoading, setIsLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [validId, setValidId] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitErrorMessage, setSubmitErrorMessage] = useState("");

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}survey/getSurvey/${id}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.message === 'No survey found') {
                    setErrorMessage("No Survey Found");
                } else {
                    setCustomer({
                        name: data.survey.name,
                        address: data.survey.address,
                        phone: data.survey.phone,
                        email: data.survey.email,
                        salesRep: data.survey.salesRep,
                        systemSize: data.survey.systemSize
                    });
                    setIsLoading(false);
                    setValidId(true);
                }
            })
            .catch((error) => {
                console.error('Error fetching customer data:', error);
                setIsLoading(false);
                setValidId(false);
            });
    }, [id]);

    async function requestSignedUrls(fileDetails) {
        const url = `${process.env.REACT_APP_API_URL}survey/generateSignedUrls/${id}`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(fileDetails),
            headers: { 'Content-Type': 'application/json' },
        });
        if (!response.ok) {
            throw new Error('Failed to get signed URLs');
        }
        return await response.json();
      }
  
      function prepareFileDetails() {
        const filesMapping = {};
      
        // Review Images
        if (reviews.reviewImages && reviews.reviewImages.length > 0) {
          filesMapping['reviewImages'] = reviews.reviewImages.map((file, index) => ({
            fileName: `reviewImages_${Date.now()}_${index}.${getFileExtension(file.name)}`,
            fileKey: 'reviewImages',
            fileType: file.type,
            fileData: file
          }));      
        }

        // Drone Images
        if (reviews.droneImages && reviews.droneImages.length > 0) {
            filesMapping['droneImages'] = reviews.droneImages.map((file, index) => ({
              fileName: `droneImages_${Date.now()}_${index}.${getFileExtension(file.name)}`,
              fileKey: 'droneImages',
              fileType: file.type,
              fileData: file
            }));      
        }

        // Video Testimonial
        if (reviews.videoTestimonial && reviews.videoTestimonial.length > 0) {
            filesMapping['videoTestimonial'] = reviews.videoTestimonial.map((file, index) => ({
              fileName: `videoTestimonial_${Date.now()}_${index}.${getFileExtension(file.name)}`,
              fileKey: 'videoTestimonial',
              fileType: file.type,
              fileData: file
            }));      
        }

        // Additional Images
        if (reviews.additionalImages && reviews.additionalImages.length > 0) {
            filesMapping['additionalImages'] = reviews.additionalImages.map((file, index) => ({
              fileName: `additionalImages_${Date.now()}_${index}.${getFileExtension(file.name)}`,
              fileKey: 'additionalImages',
              fileType: file.type,
              fileData: file
            }));      
        }
      
        return filesMapping;
      }
      
      function getFileExtension(fileName) {
        return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2); // Extracts file extension from fileName
      }

      async function uploadFileToGCS(file, signedUrl) {
        try {
            const response = await fetch(signedUrl, {
                method: 'PUT',
                body: file, // File to upload
                headers: {
                    'Content-Type': file.type,
                },
            });
    
            if (response.ok) {
                // Successfully uploaded, return the URL without query parameters
                return signedUrl.split('?')[0];
            } else {
              console.error('Upload failed:', await response.text());
              throw new Error(`Failed to upload file: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error uploading file to GCS:', error);
            throw error;
        }
    }

      async function uploadFiles(filesMapping, signedUrls) {
          const uploadResults = {};
      
          for (const [section, files] of Object.entries(filesMapping)) {
            
              const sectionUrls = signedUrls[section] || [];
      
              // Ensure we have the same number of signed URLs as we have files
              if (files.length !== sectionUrls.length) {
                  console.error(`Mismatch in files and signed URLs count for section: ${section}`);
                  continue; 
              }
      
              // Upload files concurrently
              const uploadPromises = files.map((file, index) =>
                  uploadFileToGCS(file.fileData, sectionUrls[index])
              );
      
              try {
                  // Wait for all files in this section to be uploaded
                  const sectionUploadedUrls = await Promise.all(uploadPromises);
                  uploadResults[section] = sectionUploadedUrls;
              } catch (error) {
                  console.error(`Error uploading files for section ${section}:`, error);
              }
            // }
          }
      
          return uploadResults;
      }

    const handleSurveyChange = (event) => {
        const { name, value } = event.target;
        setSatisfactionSurvey(prev => ({ ...prev, [name]: value }));
    };

    const handleReviewsChange = (name, value) => {
        setReviews(prev => ({ ...prev, [name]: value }));
    };

    const handleReferralChange = (index, event) => {
        const { name, value } = event.target;
        const updatedReferrals = referrals.map((referral, i) => i === index ? { ...referral, [name]: value } : referral);
        setReferrals(updatedReferrals);
      };
    
      const addReferral = () => {
        setReferrals([...referrals, { name: '', phone: '', email: '' }]);
      };
    
      const removeReferral = (index) => {
        setReferrals(referrals.filter((_, i) => i !== index));
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);

        try {
            const fileDetails = prepareFileDetails();
            const signedUrlsResponse = await requestSignedUrls(fileDetails);
            const uploadResults = await uploadFiles(fileDetails, signedUrlsResponse.signedUrls);
    
            const submitFormData = {
                customer,
                satisfactionSurvey,
                reviews: {
                    ...reviews,
                    reviewImages: uploadResults.reviewImages,
                    droneImages: uploadResults.droneImages,
                    videoTestimonial: uploadResults.videoTestimonial,
                    additionalImages: uploadResults.additionalImages,
                },
                referrals,
            };
    
            const url = `${process.env.REACT_APP_API_URL}survey/submitSurveyForm/${id}`;
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(submitFormData),
                headers: { 'Content-Type': 'application/json' },
            });
    
            setSubmitting(false);
    
            if (response.ok) {
                setSuccess(true);
                setReviews({
                    amount: '',
                    reviewImages: [],
                    droneImages: [],
                    videoTestimonial: [],
                    additionalImages: []
                });
                setReferrals([{ name: '', phone: '', email: '' }]);
                setSatisfactionSurvey({
                    typeOfWork: [],
                    surveyor: '',
                    salesRep: null,
                    officeSupport: null,
                    installationTeam: null,
                    overall: null,
                    improvement: '',
                    anythingElse: '',
                    solarWorking: ''
                });
            } else {
                setSubmitErrorMessage("Error submitting the form");
            }
        } catch (error) {
            setSubmitting(false);
            setSubmitErrorMessage("Error Occurred");
            console.error('Submission error:', error);
        }
    };


    return (
        <StyledContainer onSubmit={handleSubmit}>
            <Logo src={img} alt={alt} />
            <Title>Exit Survey</Title>
            {isLoading && <CustomerData>Loading Data...</CustomerData>}
            {errorMessage && <ErrorStyled>{errorMessage}</ErrorStyled>}
            {!errorMessage && (
                <>
                    <CustomerData>{customer.name}</CustomerData>
                    <CustomerData>{customer.address}</CustomerData>
                    <CustomerData>{customer.phone} {customer.email}</CustomerData>
                    <CustomerData>Sales Rep: {customer.salesRep}</CustomerData>
                    <CustomerData>System Size: {customer.systemSize}</CustomerData>
                    <br />
                    <SatisfactionSurvey survey={satisfactionSurvey} onSurveyChange={handleSurveyChange} />
                    <StyledAccordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Reviews</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="amount"
                                label="Amount of Reviews"
                                type="number"
                                inputProps={{ min: 1, max: 20 }}
                                value={reviews.amount}
                                variant="outlined"
                                onChange={(e) => handleReviewsChange('amount', e.target.value)}
                                sx={{backgroundColor:'#fff'}}
                            />
                            <MediaUpload
                                id="reviewImages"
                                label="Review Images"
                                selectedFiles={reviews.reviewImages}
                                onFilesSelected={(files) => handleReviewsChange('reviewImages', files)}
                            />
                            <MediaUpload
                                id="droneImages"
                                label="Drone Images"
                                selectedFiles={reviews.droneImages}
                                onFilesSelected={(files) => handleReviewsChange('droneImages', files)}
                            />
                            <MediaUpload
                                id="videoTestimonial"
                                label="Video Testimonial"
                                selectedFiles={reviews.videoTestimonial}
                                onFilesSelected={(files) => handleReviewsChange('videoTestimonial', files)}
                            />
                            <MediaUpload
                                id="additionalImages"
                                label="Additional Images"
                                selectedFiles={reviews.additionalImages}
                                onFilesSelected={(files) => handleReviewsChange('additionalImages', files)}
                            />
                        </AccordionDetails>
                    </StyledAccordion>
                    <ReferralSection
                        referrals={referrals}
                        onReferralChange={handleReferralChange}
                        addReferral={addReferral}
                        removeReferral={removeReferral}
                    />
                    <br />
                    {success && <SuccessStyled>Success</SuccessStyled>}
                    {submitErrorMessage && <ErrorStyled>{submitErrorMessage}</ErrorStyled>}
                    <br />
                    {!submitting ? (
                        <Button type="submit" variant="contained" disabled={!validId}>Submit</Button>
                    ) : (
                        <Button type="submit" variant="contained" disabled={!validId}>
                            <Oval height='1.5rem' />
                        </Button>
                    )}
                </>
            )}
        </StyledContainer>
    );
}

export default SurveyForm;
