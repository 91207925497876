import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import { TextField as MuiTextField, FormControl as MuiFormControl, Button } from '@mui/material';


// Container for the entire form, ensuring everything is centered
export const StyledContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding-bottom: 20px;
  width: 95%;
  max-width: 600px; // Adjust based on your preference for mobile view
`;

// Styling for the logo at the top of the form
export const Logo = styled.img`
  max-width: 80%;
  height: auto;
  /* margin-bottom: 20px; // Space below the logo */
`;

// Title styling for "New Deal"
export const Title = styled.h1`
  font-size: 36px; // Mobile-friendly font size
  color: #333; // Dark color for text
  margin-bottom: 15px; // Space below the title
  font-family: 'Avenir_Black';
`;

// Styling for displaying static customer data
export const CustomerData = styled.p`
  font-size: 18px; // Readable text size for mobile
  color: #666; // Slightly lighter text color for contrast
  margin: 5px 0; // Margin for spacing between data rows
  max-width: 95%;
  text-align: center;
  font-family: 'Avenir_Book';
`;

// Accordion styling adjustments (if needed, you might extend MUI components like this)
export const StyledAccordion = styled(Accordion)`
  && {
    width: 95%;
    box-shadow: none; // Remove default shadow for a cleaner look
    margin-bottom: 10px; // Spacing between each accordion
    background-color: #EEEEEE;

    .MuiAccordionSummary-root {
      background-color: #f7f7f7; // Light background for the accordion summary
    }

    .MuiAccordionDetails-root {
      padding: 16px; // Padding inside the accordion details
    }
  }
`;

// Container for image previews in the form
export const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: nowrap; // Prevent wrapping to a new line
  gap: 10px; // Spacing between images
  margin: 5px;
  /* margin-top: 10px; // Margin above the image container */
  overflow-x: auto; // Enable horizontal scrolling for multiple images
`;

// Individual image preview styling
export const ImagePreview = styled.div`
  position: relative;
  width: 100px; // Fixed width for square aspect ratio
  height: 100px; // Fixed height for square aspect ratio
  border: 1px solid #ccc; // Border to distinguish image area
  border-radius: 8px; // Rounded corners for aesthetics
  overflow: hidden; // Ensure images do not overflow the container

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // Ensure the image covers the area
  }
`;

export const ImageUploadContainer = styled.div`
  background-color: #fff;
  border: solid 1px;
  border-color: lightgrey;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  
`;

// Upload button container styling
export const UploadButtonContainer = styled.div`
  display: flex;
  justify-content: center; // Center the buttons horizontally
  gap: 20px; // Spacing between buttons
  margin-top: 20px; // Margin above the buttons
`;

export const FormControl = styled(MuiFormControl)`
  && {
    margin: 10px 0; // Adds margin around each form control for better spacing
    width: 100%; // Ensures form controls take up the full width of the container
    background-color: #ffffff;
    .MuiInputLabel-root {
      background-color: #ffffff; // Matches the background to avoid label overlap issues
      padding: 0 4px; // Adds a little padding around the label text
    }

    .MuiSelect-select {
      height: 25px; // Standardizes the height for select elements
    }
  }
`;

export const TextField = styled(MuiTextField)`
  && {
    margin: 10px 0; // Adds margin around each text field for better spacing
    width: 100%; // Ensures text fields take up the full width of the container
    background-color: #fff;
    .MuiInputBase-input {
      height: 25px; // Standardizes the height for text input fields
    }
  }
`;

export const SuccessStyled = styled.h3`
  color: #4caf50; // Dark color for text
  font-family: 'Avenir_Book';
  text-align: center;
`;

export const ErrorStyled = styled.h2`
  color: #ef5350; // Dark color for text
  font-family: 'Avenir_Book';
`;

export const RoundButton = styled(Button)({
  backgroundColor: "#fff",
  '&.selected': {
    backgroundColor: "#1BA8F1",
    color: "#fff",
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    margin: '5px',
  },
  '&.notSelected': {
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    margin: '5px',
  },
});