import React, { useRef, useEffect, useState } from 'react';
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
import styled from "styled-components";
import { useParams } from 'react-router-dom';

const MapboxContainer = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Rejections = () => {

    // @ts-ignore
    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

    const mapstyle = 'mapbox://styles/dsipos05/clv4epydh02b901pkcpcy01jw';
    const mapContainerRef = useRef(null);

    const [lng, setLng] = useState(-78.7);
    const [lat, setLat] = useState(37.6);
    const [zoom, setZoom] = useState(2.96);
    const [mapboxToken, setMapboxToken] = useState('');

    const { id } = useParams();

    useEffect(() => {
        const verifyRejectionToken = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}service/verifyRejectionToken/${id}`);
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message || 'Network response was not ok');
                }
                const data = await response.json();
                setMapboxToken(data.token);
            } catch (error) {
                console.error('Failed to fetch token:', error);
            }
        };
        verifyRejectionToken();
    }, [id]);

    // Initialize map when component mounts
    useEffect(() => {
        if (!mapboxToken) return; // Do not initialize map without token

        mapboxgl.accessToken = mapboxToken;

        const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: mapstyle,
        center: [lng, lat],
        zoom: zoom,
        });
        

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');

        // Event to display popup on feature click
        map.on('click', (e) => {
            // Get features that the user clicked on
            const features = map.queryRenderedFeatures(e.point); // specify the layer ID if you have specific layers

            if (features.length > 0) {
                const feature = features[0];

                // Create HTML content for the popup
                const popupContent = `
                    <div><strong>Address:</strong> ${feature.properties.address}</div>
                    <div><strong>Name:</strong> ${feature.properties.name}</div>
                    <div><strong>Phone:</strong> ${feature.properties.phone}</div>
                    <div><strong>Stage:</strong> ${feature.properties.projectBlockStage}</div>
                    <div><strong>Comments:</strong> ${feature.properties.comments}</div>
                `;

                // Popup feature
                new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(popupContent)
                    .addTo(map);
            }
        });

        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

        // Clean up on unmount
        return () => map.remove();
    }, [mapboxToken]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <MapboxContainer className='mapboxgl-map' ref={mapContainerRef} />
    )
};

export default Rejections