import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import portfolioImage from '../../images/commercial/port.webp'; // Replace with your image path
import logo from '../../images/lunex-color-transparent-slogan.webp'; // Replace with your logo path

const CustomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff; // Adjust the color to match the image's background
  margin: 50px;

  @media (max-width: 768px) {
    margin: 5px;
  }
`;

const Content = styled(Box)({
  maxWidth: '1000px',
  width: '100%',
  color: '#ffffff', // Adjust the color to match the image's text color
  textAlign: 'left',
});

const Portfolio = () => {
  return (
    <CustomContainer>
      <Content>
        <Typography variant="h3" component="h3" sx={{ color: '#FFA500', textAlign: 'right', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', margin: '20px' }}>
          PORTFOLIO
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '20px' }}>
          <img src={logo} alt="Lunex Power" style={{ width: '200px', height: 'auto' }} />
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          <img src={portfolioImage} alt="Portfolio" style={{ width: '100%', height: 'auto' }} />
        </Box>
      </Content>
    </CustomContainer>
  );
};

export default Portfolio;