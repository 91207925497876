import React from 'react';
import { Box, Typography, Grid, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import ceoImage from '../../images/commercial/Dan.png';

const CustomContainer = styled(Box)({
//   width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '50px',
  backgroundColor: '#fff', // Adjust the color to match the image's background
//   padding: '50px 20px',

});

const Content = styled(Box)({
  maxWidth: '1000px',
  position: 'relative',
  color: '#ffffff', // Adjust the color to match the image's text color
  textAlign: 'left',
});

const TitleWrapper = styled('div')({
    width: '400px'
})

const VerticalBars = styled('div')({
  position: 'absolute',
  top: 0,
  left: '-40px', // Adjusted to make sure it aligns with the edge
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '60%',
  padding: '20px 0',

  '&::before, &::after, div': {
    content: '""',
    width: '5px',
    backgroundColor: '#1BA8F1',
    margin: '20px 0',
  },

  '&::before': {
    height: '300px', // Large
  },

  div: {
    height: '160px', // Medium
  },

  '&::after': {
    height: '120px', // Small
  },
});

const SmallRectangle = styled('div')({
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  width: '50px',
  height: '50px',
  backgroundColor: '#33c0f4', // Slightly lighter color
  opacity: 0.5, // Slightly transparent
});

const WhyLunex = () => {
  return (
    <CustomContainer>
      <Content>
        {/* <TitleWrapper> */}
        <Typography variant="h3" component="h3" sx={{ color: '#FFA500', textAlign: 'center', marginBottom: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
          WHY PARTNER WITH LUNEX
        </Typography>
        {/* </TitleWrapper> */}
        <Typography variant="h5" component="p" sx={{ fontStyle: 'italic', color: '#000', textAlign: 'center', marginBottom: '40px' }}>
          “Our people are our greatest asset”<br />
          <Typography variant="h6" component="span" sx={{ fontStyle: 'italic', color: '#000' }}>
            Dan Gawrych, CEO
          </Typography>
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar src={ceoImage} alt="Dan Gawrych, CEO" sx={{ width: '300px', height: '350px', borderRadius: '5px' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold', color: '#000', textAlign: 'left', marginBottom: '20px' }}>
              WE HAVE DEVELOPED FUTURE-READY PROJECTS FOR 2,900+ CLIENTS.
            </Typography>
            <Typography variant="body1" component="p" sx={{ color: '#000', textAlign: 'left', lineHeight: 2.0, marginBottom: '20px' }}>
              What sets Lunex Power apart is our exceptional team — one of the strongest in the industry, comprised of diverse and talented professionals. Our team is led by our CEO, Dan Gawrych. Dan has steadfastly adhered to the philosophy that "a company's employees are its greatest asset — your people are your product." This guiding principle ensures that our day-to-day operations prioritize the value and expertise of our team members, with acute focus on delivering our client's desired business outcomes.
            </Typography>
            <Typography variant="body1" component="p" sx={{ color: '#000', textAlign: 'left', lineHeight: 2.0 }}>
              With over 50 years of combined experience, Lunex Power has successfully delivered more than 250 megawatts of clean energy to residents and businesses. Our completed projects include utility-scale solar farms and installations for over 900 homes and businesses annually across the eastern United States. Lunex Power was meticulously built from the ground up with a strong emphasis on engineering, procurement, and operations. Initially, we began as a modest installation firm, establishing a robust foundation for long-term growth. This strategic approach has attracted top talent to our organization, enabling us to assemble one of the most skilled and experienced teams in the industry.
            </Typography>
          </Grid>
          
        </Grid>
        <VerticalBars>
          <div></div>
        </VerticalBars>
        {/* <SmallRectangle /> */}
      </Content>
    </CustomContainer>
  );
};

export default WhyLunex;
