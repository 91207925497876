import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import FontStyles from './fontStyles';
import {createRoot} from 'react-dom/client';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <>
    <FontStyles />
    <App />
  </>,
);

